import { useContext, useEffect, useState } from 'react';

import { AuthContext } from '../contexts/AuthContext';
import { getModules } from '../services/module';

const useModules = () => {
  const [modules, setModules] = useState([]);
  const [isModulesLoading, setIsModulesLoading] = useState(true);
  const { dispatch } = useContext(AuthContext);

  useEffect(() => {
    getModules()
      .then(data => {
        if (data.success) {
          setModules(data.result);
          setIsModulesLoading(false);
        } else {
          dispatch({type: 'httpStatusCode', httpStatusCode: data.code});
        }
      });
  }, [dispatch]);

  return {
    modules,
    isModulesLoading
  };
}

export default useModules;
