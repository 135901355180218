import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import moment from 'moment';

import Alert from '../../../components/alert';
import HttpStatusCode from '../../../components/http-status-code';
import PageHeader from '../../../components/page-header';
import Sidebar from '../shared/sidebar';
import useAlert from '../../../hooks/useAlert';
import useCensuses from '../../../hooks/useCensuses';
import useCompany from '../../../hooks/useCompany';
import { AuthContext } from '../../../contexts/AuthContext';
import { downloadCensusData } from '../../../services/census';
import { truncateContent } from '../../../utils/helper';

function Censuses(props) {
  const { match: { params } } = props;
  const companyId = params.companyId;
  const { location: { state = {} } } = props;
  const { stateAlert = {} } = state || {};
  const { company } = useCompany(companyId);
  const { censuses } = useCensuses(companyId);
  const { alert } = useAlert(stateAlert);
  const { dispatch } = useContext(AuthContext);
  const header = company.name + ' - ' + company.city + ', ' + company.state;
  
  function handleDownloadCensusData(censusId, name) {
    downloadCensusData(company.id, censusId)
      .then(data => {
        if (data) {
          const objectUrl = window.URL.createObjectURL(data);
          const downloadLink = document.createElement('a');
          downloadLink.href = objectUrl;
          downloadLink.download = `${name}.sql`;
          downloadLink.click();
        } else {
          dispatch({type: 'httpStatusCode', httpStatusCode: data.code});
        }
      });
  }

  return (
    <React.Fragment>
      <HttpStatusCode prevMatch={props.match} />
      <div className="container">
        <div className="row">
          <div className="col-sm-2">
            <Sidebar companyId={company.id} />
          </div>
          <div className="col-sm-10">
            <PageHeader header={header} />
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to={`/company/${company.id}`}>Company</Link></li>
              <li className="breadcrumb-item active">Censuses</li>
            </ol>
            <Alert alert={alert} />
            {censuses.length > 0 ?
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">API Url</th>
                  <th scope="col">Table Name</th>
                  <th scope="col">Description</th>
                  <th scope="col">Date</th>
                  <th colSpan="2" className="icon text-right"><Link to={`/company/${company.id}/censuses/create`}><button type="button" className="btn btn-info btn-sm">Create</button></Link></th>
                </tr>
              </thead>
              <tbody>
                {censuses.map((census, index) => (
                <tr key={index}>
                  <td data-toggle="tooltip" data-placement="top" title={census.url}>{truncateContent(census.url, 30,)}</td>
                  <td>{census.name}</td>
                  <td>{census.description}</td>
                  <td>{moment(census.created_at).format('L')}</td>
                  <td className="text-right"><i aria-label="Download" className="fas fa-file-download fa-cph" title="Download" aria-hidden onClick={() => handleDownloadCensusData(census.id, census.name)}></i></td>
                  <td className="text-right"><Link to={`/company/${company.id}/censuses/${census.id}/edit`}><i aria-label="Edit" className="fas fa-edit" title="Edit" aria-hidden></i></Link></td>
                </tr>
                ))}
              </tbody>
            </table> :
            <p>No censuses found. Click <Link to={`/company/${company.id}/censuses/create`}>here</Link> to create census.</p>
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Censuses;
