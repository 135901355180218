import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import Button from '../../components/button';
import useForm from '../../hooks/useForm';
import validate from './registerFormValidation';
import { register } from '../../services/auth';

function RegisterForm(props) {
  const initialState = {
    name: '',
    email: '',
    confirmEmail: '',
    password: ''
  };
  const recaptchaAction = 'register';
  const { values, handleChange, handleSubmit, isButtonLoading, setIsButtonLoading, errors } = useForm(initialState, handleRegister, validate, recaptchaAction);

  function handleRegister() {
    console.log(values);
    register(values)
      .then(data => {
        setTimeout(() => {
          setIsButtonLoading(false);

          if (data.success) {
            props.history.push('/verify');
          } else {
            props.onAlert({
              text: data.code === 404 ? 'The request URI is incorrect, or the resource does not exist.' : 'The request was not accepted due to bad syntax, missing parameters, insufficient data, etc.',
              type: 'danger'
            });
          }
        }, 1000 / 1);
      });
  }

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="form-group row">
        <label htmlFor="name" className="col-sm-3 col-form-label">Name</label>
        <div className="col-sm-6">
          <input type="text" id="name" name="name" value={values.name} onChange={handleChange} className={`form-control ${errors.name && 'is-invalid'}`} />
          {errors.name && (
            <div className="invalid-feedback">
              {errors.name}
            </div>
          )}
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="email" className="col-sm-3 col-form-label">Email</label>
        <div className="col-sm-6">
          <input type="email" id="email" name="email" value={values.email} onChange={handleChange} className={`form-control ${errors.email && 'is-invalid'}`} />
          {errors.email && (
            <div className="invalid-feedback">
              {errors.email}
            </div>
          )}
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="city" className="col-sm-3 col-form-label">Confirm Email</label>
        <div className="col-sm-6">
          <input type="input" id="confirmEmail" name="confirmEmail" value={values.confirmEmail} onChange={handleChange} className={`form-control ${errors.confirmEmail && 'is-invalid'}`} />
          {errors.confirmEmail && (
            <div className="invalid-feedback">
              {errors.confirmEmail}
            </div>
          )}
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="password" className="col-sm-3 col-form-label">Password</label>
        <div className="col-sm-6">
          <input type="password" id="password" name="password" value={values.password} onChange={handleChange} className={`form-control ${errors.password && 'is-invalid'}`} />
          {errors.password && (
            <div className="invalid-feedback">
              {errors.password}
            </div>
          )}
        </div>
      </div>
      <div className="form-group row">
        <div className="col-sm-6 offset-sm-3">
          <small id="registerHelp" className="form-text text-muted">By clicking 'Register', you consent to the <Link to="/policies/tos">Terms of Service</Link> and <Link to="/policies/privacy">Privacy Policy</Link>.</small>
        </div>
      </div>
      <div className="form-group row">
        <div className="col-sm-6 offset-sm-3">
          <Button isButtonLoading={isButtonLoading} >Submit</Button>
        </div>
      </div>
    </form>
  );
}

export default withRouter(RegisterForm);
