import React from 'react';
import { Link } from 'react-router-dom';

import Alert from '../../../../components/alert';
import HttpStatusCode from '../../../../components/http-status-code';
import NavTabs from '../shared/nav-tabs';
import PageHeader from '../../../../components/page-header';
import RoleForm from './RoleForm';
import Sidebar from '../../shared/sidebar';
import useAlert from '../../../../hooks/useAlert';
import useCompany from '../../../../hooks/useCompany';
import useCompanyModules from '../../../../hooks/useCompanyModules';
import useCompanyModuleResources from '../../../../hooks/useCompanyModuleResources';
import useRole from '../../../../hooks/useRole';

function EditRole(props) {
  const { match: { params } } = props;
  const companyId = params.companyId;
  const roleId = params.roleId;
  const { company, isCompanyLoading } = useCompany(companyId);
  const { companyModules, isCompanyModulesLoading } = useCompanyModules(companyId);
  const { resources, isResourcesLoading } = useCompanyModuleResources(companyId);
  const { role, isRoleLoading } = useRole(companyId, roleId);
  const { alert, setAlert } = useAlert({});
  const header = company.name + ' - ' + company.city + ', ' + company.state;

  function handleAlert(alert) {
    setAlert(alert);
  }

  return (
    <React.Fragment>
      <HttpStatusCode prevMatch={props.match} />
      <div className="container">
        <div className="row">
          <div className="col-sm-2">
            <Sidebar companyId={company.id} />
          </div>
          <div className="col-sm-10">
            <PageHeader header={header} />
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to={`/company/${company.id}`}>Company</Link></li>
              <li className="breadcrumb-item"><Link to={`/company/${company.id}/roles`}>Roles</Link></li>
              <li className="breadcrumb-item active">Edit</li>
            </ol>
            <h3>{role.name}</h3>
            <NavTabs companyId={company.id} roleId={role.id} />
            <Alert alert={alert} />
            <p>Roles determine what a user can and cannot do within a company.</p>
            {(!isCompanyLoading && !isCompanyModulesLoading && !isResourcesLoading && !isRoleLoading) &&
            <RoleForm companyId={company.id} companyModules={companyModules} resources={resources} role={role} onAlert={handleAlert} />
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default EditRole;
