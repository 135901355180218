import jwtDecode from 'jwt-decode';

function decodeToken(token) {
  const payload = jwtDecode(token);

  return payload;
}

export function getToken() {
  const token = localStorage.getItem('token');

  return token;
}

export function getTokenState() {
  const tokenState = {
    id: null,
    name: null,
    userType: null,
    isLoggedIn: false,
    companies: null,
    httpStatusCode: null
  };
  const token = getToken();

  if (!token) {
    return tokenState;
  }

  const payload = decodeToken(token);

  if (!isTokenExpired(payload)) {
    return {
      id: payload.id,
      name: payload.name,
      userType: payload.userType,
      isLoggedIn: true,
      companies: payload.companies,
      httpStatusCode: null
    };
  }

  return tokenState;
}

export function updateAuthState(authState) {
  const tokenState = getTokenState();
  const newAuthState = {...tokenState, ...authState};

  return newAuthState;
}

function isTokenExpired(payload) {
  const now = Math.floor(Date.now() / 1000);

  if (typeof payload.exp !== 'undefined' && payload.exp < now) {
    return true;
  }

  if (typeof payload.nbf !== 'undefined' && payload.nbf > now) {
    return true;
  }

  return false;
}

export function setToken(token) {
  localStorage.setItem('token', token);
}
