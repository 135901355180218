export function camelToSnakeCase(str) {
    return str.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1_$2').toLowerCase();
}

export function snakeToCamelCase(str) {
  return str.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));
}

export function hasCompanyModule(companyModules, moduleCode) {
  return companyModules.some(companyModule => companyModule.code === moduleCode);
}

export function truncateContent(content, limit, after) {
  if (!content || !limit) {
    return 'Missing content or limit';
  };

  content = content.trim();

  // convert the content into an array of words. Remove any words above the limit
  content = content.split(' ').slice(0, limit);

  // convert the array of words back into a string.
  content = content.join(' ');

  // truncate content
  content = content.length > limit ? content.substr(0, limit - 1) + (after ? after : ' ...') : content;

  return content;
};
