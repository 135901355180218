import { useContext, useEffect, useState } from 'react';

import { AuthContext } from '../contexts/AuthContext';
import { getUsers } from '../services/user';

const useUsers = () => {
  const [users, setUsers] = useState([]);
  const [isUsersLoading, setIsUsersLoading] = useState(true);
  const { dispatch } = useContext(AuthContext);

  useEffect(() => {
    getUsers()
      .then(data => {
        if (data.success) {
          setUsers(data.result);
          setIsUsersLoading(false);
        } else {
          dispatch({type: 'httpStatusCode', httpStatusCode: data.code});
        }
      });
  }, [dispatch]);

  return {
    users,
    isUsersLoading
  };
}

export default useUsers;
