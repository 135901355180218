import { useEffect, useState } from 'react';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const useRecaptcha = (recaptchaAction, canSubmit) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [isRecaptchaLoading, setIsRecaptchaLoading] = useState(true);

  useEffect(() => {
    async function getRecaptchaToken() {
      if (recaptchaAction) {
        if (!executeRecaptcha) {
          return;
        }

        const result = await executeRecaptcha(recaptchaAction);

        setRecaptchaToken(result);
        setIsRecaptchaLoading(false);
      }
    }

    getRecaptchaToken();
  }, [recaptchaAction, canSubmit, executeRecaptcha]);

  return {
    recaptchaToken,
    isRecaptchaLoading
  };
}

export default useRecaptcha;
