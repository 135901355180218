import { getHeaders, logError, readResponseAsJSON, validateResponse } from '../utils/fetch';

export function createCompanyModule(body) {
  const headers = getHeaders();
  const options = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: headers
  };

  return fetch('/api/companies/' + body.companyId + '/modules', options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}

export function getCompanyModules(companyId) {
  const headers = getHeaders();
  const options = {
    method: 'GET',
    headers: headers
  };

  return fetch('/api/companies/' + companyId + '/modules', options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}

export function getCompanyModuleResources(companyId) {
  const headers = getHeaders();
  const options = {
    method: 'GET',
    headers: headers
  };

  return fetch('/api/companies/' + companyId + '/modules/resources', options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}

export function deleteCompanyModule(companyId, moduleId) {
  const headers = getHeaders();
  const options = {
    method: 'DELETE',
    headers: headers
  };

  return fetch('/api/companies/' + companyId + '/modules/' + moduleId, options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}
