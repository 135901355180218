import React from 'react';
import { NavLink } from 'react-router-dom';

function NavTabs(props) {
  const moduleId = props.moduleId;

  return (
    <ul className="nav nav-tabs mb-4">
      <li className="nav-item">
        <NavLink className="nav-link" to={`/admin/modules/${moduleId}/edit`}>Edit</NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to={`/admin/modules/${moduleId}/resources`}>Resources</NavLink>
      </li>
    </ul>
  );
}

export default NavTabs;
