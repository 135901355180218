import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Dashboard from '../pages/user/dashboard';
import Email from '../pages/user/settings/email';
import NotFound from '../pages/not-found';
import Password from '../pages/user/settings/password';
import PrivateUserRoute from './PrivateUserRoute';
import Profile from '../pages/user/settings/profile';
import UserCompanies from '../pages/user/companies';

const UserRoutes = () => (
  <Switch>
    <PrivateUserRoute exact path='/user' component={Dashboard} />
    <PrivateUserRoute path='/user/settings/profile' component={Profile} />
    <PrivateUserRoute path='/user/settings/email' component={Email} />
    <PrivateUserRoute path='/user/settings/password' component={Password} />
    <PrivateUserRoute exact path='/user/companies' component={UserCompanies} />
    <Route component={NotFound} />
  </Switch>
)

export default UserRoutes
