import React from 'react';

import HttpStatusCode from '../../../../components/http-status-code';
import PageHeader from '../../../../components/page-header';
import CompanyForm from './CompanyForm';
import Sidebar from '../../shared/sidebar';

function CreateCompany(props) {
  const header = 'Administrator';

  return (
    <React.Fragment>
      <HttpStatusCode prevMatch={props.match} />
      <div className="container">
        <div className="row">
          <div className="col-sm-2">
            <Sidebar />
          </div>
          <div className="col-sm-10">
            <PageHeader header={header} />
            <CompanyForm />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CreateCompany;
