import { getToken } from '../utils/token';

export function getHeaders() {
  let headers = new Headers();
  const token = getToken();

  headers.append('Content-Type', 'application/json');

  if (token) {
    headers.append('Authorization', `Bearer ${token}`);
  }

  return headers;
}

export function logResult(result) {
  // log result to database
  console.log(result);
}

export function logError(error) {
  // log error to database
  console.log(error);

  return false;
}

export function readResponseAsBlob(response) {
  return response.blob();
}

export function readResponseAsJSON(response) {
  return response.json();
}

export function readResponseAsText(response) {
  return response.text();
}

export function validateResponse(response) {
  if (!response.ok && response.status !== 400 & response.status !== 401 & response.status !== 403 & response.status !== 404 & response.status !== 409) {
    return Promise.reject({
      code: response.status,
      message: 'The server was unable to complete the request because of unexpected error.',
      status: response.statusText,
      success: false
    });
  } else if (response.status === 204) { // 204 will return an empty body and will cause error in readResponseAsJSON()
    // create a new Response with a default 200 status and pass in 204 status as a workaround
    return new Response(JSON.stringify({
      code: 204,
      message: 'The request was successful, but there is no need to return any data.',
      status: 'No Content',
      success: true
    }));
  }

  return response;
}
