import React from 'react';
import { Link } from 'react-router-dom';

import CompanyUserRoleForm from './CompanyUserRoleForm';
import HttpStatusCode from '../../../../../components/http-status-code';
import NavTabs from '../../shared/nav-tabs';
import PageHeader from '../../../../../components/page-header';
import Sidebar from '../../../shared/sidebar';
import useCompany from '../../../../../hooks/useCompany';
import useCompanyUser from '../../../../../hooks/useCompanyUser';
import useRoles from '../../../../../hooks/useRoles';

function CreateCompanyUserRole(props) {
  const { match: { params } } = props;
  const companyId = params.companyId;
  const userId = params.userId;
  const { company, isCompanyLoading } = useCompany(companyId);
  const { user, isUserLoading } = useCompanyUser(companyId, userId);
  const { roles, isRolesLoading } = useRoles(companyId);
  const header = company.name + ' - ' + company.city + ', ' + company.state;

  return (
    <React.Fragment>
      <HttpStatusCode prevMatch={props.match} />
      <div className="container">
        <div className="row">
          <div className="col-sm-2">
            <Sidebar companyId={company.id} />
          </div>
          <div className="col-sm-10">
            <PageHeader header={header} />
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to={`/company/${company.id}`}>Company</Link></li>
              <li className="breadcrumb-item"><Link to={`/company/${company.id}/roles`}>Roles</Link></li>
              <li className="breadcrumb-item active">Edit</li>
            </ol>
            <h3>{user.name}</h3>
            <NavTabs companyId={company.id} userId={user.id} />
            <p>Determine the access level or permissions of the user by assigning roles.</p>
            {(!isCompanyLoading && !isUserLoading && !isRolesLoading) &&
            <CompanyUserRoleForm companyId={company.id} userId={user.id} companyUserId={user.company_user_id} roles={roles} />
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CreateCompanyUserRole;
