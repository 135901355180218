export default async function validate(values) {
  let errors = {};
  
  if (!values.password) {
    errors.password = 'Password is required';
  } else if (values.password.length < 8) {
    errors.password = 'Password must be at least 8 characters';
  }

  if (values.confirmPassword !== values.password) {
    errors.confirmPassword = 'Password does not match';
  }

  return await errors;
};
