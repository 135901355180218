import { checkCensusName } from '../../../../services/census';

// using async/await to handle asynchronous checkTableName to get data and prevent empty errors.name on return
export default async function validate(values) {
  let errors = {};

  if (!values.url) {
    errors.url = 'API URL is required';
  } else {
    const urlRegex = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www\.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/;

    if (!urlRegex.test(values.url)) {
      errors.url = 'API URL must be a valid uri';
    }
  }

  if (!values.name) {
    errors.name = 'Table Name is required';
  } else {
    if (!values.hasOwnProperty('currentName') || (values.hasOwnProperty('currentName') && values.currentName !== values.name)) {
    await checkCensusName(values.companyId, values.name)
      .then(data => {
        if (!data.success) {
          // set error if user jwt session is still valid. If unauthorized, let HttpStatusCode component redirect user to log in page
          if (data.code !== 401) {
            errors.name = 'Table Name already exists';
          }
        }
      });
    }
  }

  return await errors;
};
