import React from 'react';

import Alert from '../../../components/alert';
import PageHeader from '../../../components/page-header';
import ResetPasswordForm from './ResetPasswordForm';
import useAlert from '../../../hooks/useAlert';
import useCheckResetPasswordToken from '../../../hooks/useCheckResetPasswordToken';

function ResetPassword(props) {
  const header = 'Reset Password';
  const { alert, setAlert } = useAlert({});
  const { match: { params } } = props;
  const token = params.token;
  const { isResetPasswordTokenValid, isResetPasswordTokenLoading } = useCheckResetPasswordToken(token);

  function handleAlert(alert) {
    setAlert(alert);
  }

  if (!isResetPasswordTokenValid && !isResetPasswordTokenLoading) {
    props.history.push({
      pathname: '/password/forgot',
      state: {
        stateAlert: {
          text: 'The reset password token is invalid or expired.',
          type: 'danger'
        }
      }
    });
  }

  return (
    <div className="container">
      <PageHeader header={header} />
      <Alert alert={alert} />
      {(isResetPasswordTokenValid && !isResetPasswordTokenLoading) &&
      <ResetPasswordForm token={token} onAlert={handleAlert} />
      }
    </div>
  );
}

export default ResetPassword;
