import { useContext, useEffect, useState } from 'react';

import { AuthContext } from '../contexts/AuthContext';
import { getResource } from '../services/resource';

const useResource = (moduleId, resourceId) => {
  const [resource, setResource] = useState({});
  const [isResourceLoading, setIsResourceLoading] = useState(true);
  const { dispatch } = useContext(AuthContext);

  useEffect(() => {
    getResource(moduleId, resourceId)
      .then(data => {
        if (data.success) {
          setResource(data.result);
          setIsResourceLoading(false);
        } else {
          dispatch({type: 'httpStatusCode', httpStatusCode: data.code});
        }
      });
  }, [moduleId, resourceId, dispatch]);

  return {
    resource,
    isResourceLoading
  };
}

export default useResource;
