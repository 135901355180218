import { useContext, useEffect, useState } from 'react';

import { AuthContext } from '../contexts/AuthContext';
import { getCompanyUserRoles } from '../services/companyUserRole';

const useCompanyUserRoles = (companyId, userId) => {
  const [roles, setRoles] = useState([]);
  const [isRolesLoading, setIsRolesLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const { dispatch } = useContext(AuthContext);

  useEffect(() => {
    getCompanyUserRoles(companyId, userId)
      .then(data => {
        if (data.success) {
          setRoles(data.result);
          setIsRolesLoading(false);
          setRefresh(false);
        } else {
          dispatch({type: 'httpStatusCode', httpStatusCode: data.code});
        }
      });
  }, [companyId, userId, refresh, dispatch]);

  return {
    roles,
    isRolesLoading,
    setRefresh
  };
}

export default useCompanyUserRoles;
