import { getHeaders, logError, readResponseAsJSON, validateResponse } from '../utils/fetch';
import { setToken } from '../utils/token';

export function checkEmail(email) {
  const body = {email};
  const headers = getHeaders();
  const options = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: headers
  };

  return fetch('/api/register/check', options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}

export function register(body = {}) {
  const headers = getHeaders();
  const options = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: headers
  };

  return fetch('/api/register', options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}

export function activation(token) {
  const headers = getHeaders();
  const options = {
    method: 'GET',
    headers: headers
  };

  return fetch('/api/activation/' + token, options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}

export function resendActivationEmail(email) {
  const body = {email};
  const headers = getHeaders();
  const options = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: headers
  };

  return fetch('/api/activation/resend', options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}

export function login(email, password) {
  const body = { email, password };
  const headers = getHeaders();
  const options = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: headers
  };

  return fetch('/api/login', options)
    .then(validateResponse)
    .then(response => {
      return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (data.success) {
          const token = data.result.token;

          setToken(token);
        }

        return data;
      });
    })
    .catch(logError);
}

export function forgotPassword(email) {
  const body = {email};
  const headers = getHeaders();
  const options = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: headers
  };

  return fetch('/api/password/forgot', options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}

export function checkResetPasswordToken(token) {
  const headers = getHeaders();
  const options = {
    method: 'GET',
    headers: headers
  };

  return fetch('/api/password/reset/' + token, options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}

export function resetPassword(body) {
  const headers = getHeaders();
  const options = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: headers
  };

  return fetch('/api/password/reset', options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}

export function logout() {
  localStorage.removeItem('token');
}
