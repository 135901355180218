import React from 'react';
import { Link } from 'react-router-dom';

import CompanyUserForm from './CompanyUserForm';
import HttpStatusCode from '../../../../components/http-status-code';
import PageHeader from '../../../../components/page-header';
import Sidebar from '../../shared/sidebar';
import useCompany from '../../../../hooks/useCompany';

function CreateCompanyUser(props) {
  const { match: { params } } = props;
  const companyId = params.companyId;
  const { company, isCompanyLoading } = useCompany(companyId);
  const header = company.name + ' - ' + company.city + ', ' + company.state;

  return (
    <React.Fragment>
      <HttpStatusCode prevMatch={props.match} />
      <div className="container">
        <div className="row">
          <div className="col-sm-2">
            <Sidebar companyId={company.id} />
          </div>
          <div className="col-sm-10">
            <PageHeader header={header} />
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to={`/company/${company.id}`}>Company</Link></li>
              <li className="breadcrumb-item"><Link to={`/company/${company.id}/users`}>Users</Link></li>
              <li className="breadcrumb-item active">Create</li>
            </ol>
            {!isCompanyLoading &&
            <CompanyUserForm company={company} />
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CreateCompanyUser;
