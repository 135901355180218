import React from 'react';
import { Link } from 'react-router-dom';

import moment from 'moment';

import './Footer.css';

function Footer() {
  return (
    <div className="footer">
      <div className="container">
        <div className="footer-social">
          <ul className="footer-social-buttons">
            <li className="follow-btn">
            </li>
            <li className="tweet-btn">
            </li>
          </ul>
        </div>
        <p>&copy; {moment().format('Y')} Codenology &trade;</p>
        <ul className="list-inline">
          <li className="list-inline-item">
            <Link to="/about">About</Link>
          </li>
          <li className="list-inline-item muted">&middot;</li>
          <li className="list-inline-item">
            <Link to="/contact">Contact</Link>
          </li>
          <li className="list-inline-item muted">&middot;</li>
          <li className="list-inline-item">
            <Link to="/policies/privacy">Privacy</Link>
          </li>
          <li className="list-inline-item muted">&middot;</li>
          <li className="list-inline-item">
            <Link to="/policies/tos">Terms</Link>
          </li>
        </ul>
        <ul className="list-inline">
          <li className="list-inline-item">
            <a href="https://codenology.com" aria-label="Visit Codenology on Facebook" rel="noopener noreferrer" target="_blank"><i aria-hidden="true" className="fab fa-facebook-f" title="Visit Codenology on Facebook"></i><span className="sr-only">Visit Codenology on Facebook</span></a>
          </li>
          <li className="list-inline-item muted">&middot;</li>
          <li className="list-inline-item">
            <a href="https://codenology.com" aria-label="Visit Codenology on Twitter" rel="noopener noreferrer" target="_blank"><i aria-hidden="true" className="fab fa-twitter" title="Visit Codenology on Twitter"></i><span className="sr-only">Visit Codenology on Twitter</span></a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Footer;
