import React, { useCallback, useEffect, useState } from 'react';

import ActivationForm from './ActivationForm';
import Alert from '../../components/alert';
import PageHeader from '../../components/page-header';
import useAlert from '../../hooks/useAlert';
import { activation } from '../../services/auth';

function Activation(props) {
  const header = 'Activation';
  const { alert, setAlert } = useAlert({});
  const [initialized, setInitialized] = useState(false);
  const [expired, setExpired] = useState(false);
  const { match: { params } } = props;
  const token = params.token;

  const handleAlert = useCallback((alert) => {
    setAlert(alert);
  }, [setAlert]);

  useEffect(() => {
    if (!initialized) {
      if (token) {
        activation(token)
          .then(data => {
            if (data.success) {
              handleAlert({
                text: 'Your account is now activated. You\'re now able to log in.'
              });
            } else {
              handleAlert({
                text: 'The activation token is invalid or expired.',
                type: 'danger'
              });

              setExpired(true);
            }
          });
      }

      setInitialized(true);
    }
  }, [initialized, token, handleAlert]);

  return (
    <div className="container">
      <PageHeader header={header} />
      <Alert alert={alert} />
      {(!token || expired) &&
      <ActivationForm onAlert={handleAlert} />
      }
    </div>
  );
}

export default Activation;
