import { useContext, useEffect, useState } from 'react';

import { AuthContext } from '../contexts/AuthContext';
import { getRole } from '../services/role';

const useRole = (companyId, roleId) => {
  const [role, setRole] = useState({});
  const [isRoleLoading, setIsRoleLoading] = useState(true);
  const { dispatch } = useContext(AuthContext);

  useEffect(() => {
    getRole(companyId, roleId)
      .then(data => {
        if (data.success) {
          setRole(data.result);
          setIsRoleLoading(false);
        } else {
          dispatch({type: 'httpStatusCode', httpStatusCode: data.code});
        }
      });
  }, [companyId, roleId, dispatch]);

  return {
    role,
    isRoleLoading
  };
}

export default useRole;
