import { getHeaders, logError, readResponseAsJSON, validateResponse } from '../utils/fetch';

export function createUser(body) {
  const headers = getHeaders();
  const options = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: headers
  };

  return fetch('/api/users', options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}

export function getUsers() {
  const headers = getHeaders();
  const options = {
    method: 'GET',
    headers: headers
  };

  return fetch('/api/users', options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}

export function getUser(userId) {
  const headers = getHeaders();
  const options = {
    method: 'GET',
    headers: headers
  };

  return fetch('/api/users/' + userId, options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}

export function updateUser(userId, body) {
  const headers = getHeaders();
  const options = {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: headers
  };

  return fetch('/api/users/' + userId, options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}

export function updateProfile(userId, body) {
  const headers = getHeaders();
  const options = {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: headers
  };

  return fetch('/api/users/' + userId + '/profile', options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}

export function updateEmail(userId, body) {
  const headers = getHeaders();
  const options = {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: headers
  };

  return fetch('/api/users/' + userId + '/email', options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}

export function updatePassword(userId, body) {
  const headers = getHeaders();
  const options = {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: headers
  };

  return fetch('/api/users/' + userId + '/password', options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}
