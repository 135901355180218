import React from 'react';

import CompanySidebar from '../company/shared/sidebar';
import PageHeader from '../../components/page-header';
import UserSidebar from '../user/shared/sidebar';
import { CompanyProvider } from '../../contexts/CompanyContext';

function Forbidden(props) {
  const header = 'Forbidden';
  const { state = {} } = props.location;
  const { prevMatch, isCompanyUser } = state;
  const paths = prevMatch.path.split('/');
  const companyId = prevMatch.params.companyId;

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-2">
          {paths[1] === 'company' ? <CompanyProvider companyId={companyId}><CompanySidebar companyId={companyId} /></CompanyProvider> : <UserSidebar />}
        </div>
        <div className="col-sm-10">
          <PageHeader header={header} />
          <div className="alert alert-danger">
            <h4 className="alert-heading">403 Forbidden</h4>
            <p className="mb-0">{(paths[1] === 'company' && !isCompanyUser) ? 'The company does not have permission for the requested resource.' : 'You are not authorized to request this resource.'}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Forbidden;
