import { useContext, useEffect, useState } from 'react';

import { AuthContext } from '../contexts/AuthContext';
import { getCompanyModules } from '../services/companyModule';

const useCompanyModules = (companyId) => {
  const [companyModules, setCompanyModules] = useState([]);
  const [isCompanyModulesLoading, setIsCompanyModulesLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const { dispatch } = useContext(AuthContext);

  useEffect(() => {
    // hackish for "Warning: Can't perform a React state update on an unmounted component." because <Redirect> will unmount component
    let isMounted = true;

    getCompanyModules(companyId)
      .then(data => {
        if (data.success) {
          if (isMounted) {
            setCompanyModules(data.result);
            setRefresh(false);
          }

          setIsCompanyModulesLoading(false);
        } else {
          dispatch({type: 'httpStatusCode', httpStatusCode: data.code});
        }
      });

    // cleanup
    return () => {
      isMounted = false;
    };
  }, [companyId, refresh, dispatch]);

  return {
    companyModules,
    isCompanyModulesLoading,
    setRefresh
  };
}

export default useCompanyModules;
