import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';

import Alert from '../../components/alert';
import PageHeader from '../../components/page-header';
import LoginForm from './LoginForm';
import useAlert from '../../hooks/useAlert';
import { AuthContext } from '../../contexts/AuthContext';

function Login(props) {
  const header = 'Log In to Codenology';
  const { state = {} } = props.location;
  const { from } = state || false;
  const { alert, setAlert } = useAlert({});
  const { authState } = useContext(AuthContext);

  if (authState.isLoggedIn) {
    return (
      <Redirect to={from || '/user'} />
    );
  }

  function handleAlert(alert) {
    setAlert(alert);
  }

  return (
    <div className="container">
      <PageHeader header={header} />
      <Alert alert={alert} />
      <LoginForm onAlert={handleAlert} />
    </div>
  );
}

export default Login;
