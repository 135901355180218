import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import Alert from '../../../../components/alert';
import PageHeader from '../../../../components/page-header';
import NavTabs from '../../shared/nav-tabs';
import ProfileForm from './ProfileForm';
import Sidebar from '../../shared/sidebar';
import useAlert from '../../../../hooks/useAlert';
import useUser from '../../../../hooks/useUser';
import { AuthContext } from '../../../../contexts/AuthContext';

function Profile() {
  const header = 'Settings';
  const { authState } = useContext(AuthContext);
  const { user, isUserLoading } = useUser(authState.id);
  const { alert, setAlert } = useAlert({});
  
  function handleAlert(alert) {
    setAlert(alert);
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-2">
          <Sidebar />
        </div>
        <div className="col-sm-10">
          <PageHeader header={header} />
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/user/settings/profile">Settings</Link></li>
            <li className="breadcrumb-item active">Profile</li>
          </ol>
          <NavTabs />
          <Alert alert={alert} />
          {!isUserLoading &&
          <ProfileForm user={user} onAlert={handleAlert} />
          }
        </div>
      </div>
    </div>
  );
}

export default Profile;
