import React from 'react';
import { Route, Switch } from 'react-router-dom';

import About from '../pages/about';
import Activation from '../pages/activation';
import AdminRoutes from './AdminRoutes';
import CompanyRoutes from './CompanyRoutes';
import Contact from '../pages/contact';
import Forbidden from '../pages/forbidden';
import ForgotPassword from '../pages/password/forgot';
import Home from '../pages/home';
import Login from '../pages/login';
import NotFound from '../pages/not-found';
import Privacy from '../pages/policies/privacy';
import Register from '../pages/register';
import ResetPassword from '../pages/password/reset';
import Sites from '../pages/sites';
import Tos from '../pages/policies/tos';
import UserRoutes from './UserRoutes';
import Verify from '../pages/verify';

const AppRoutes = () => (
  <Switch>
    <Route exact path='/' component={Home} />
    <Route path='/sites' component={Sites} />
    <Route path='/register' component={Register} />
    <Route path='/verify' component={Verify} />
    <Route path='/activation/:token?' component={Activation} />
    <Route path='/login' component={Login} />
    <Route path='/password/forgot' component={ForgotPassword} />
    <Route path='/password/reset/:token' component={ResetPassword} />
    <Route path='/about' component={About} />
    <Route path='/contact' component={Contact} />
    <Route path='/policies/privacy' component={Privacy} />
    <Route path='/policies/tos' component={Tos} />
    <Route path='/user' component={UserRoutes} />
    <Route path='/admin' component={AdminRoutes} />
    <Route path='/company' component={CompanyRoutes} />
    <Route path='/forbidden' component={Forbidden} />
    <Route component={NotFound} />
  </Switch>
)

export default AppRoutes
