import React, { createContext } from 'react';

import useAuth from '../hooks/useAuth';
import { getTokenState } from '../utils/token';

const initialState = {
  name: null,
  userType: null,
  isLoggedIn: false,
  companies: null,
  httpStatusCode: null
};
const AuthContext = createContext(initialState);

const AuthProvider = ({ children }) => {
  const tokenState = getTokenState();
  const { authState, dispatch } = useAuth(tokenState);

  return (
    <AuthContext.Provider value={{ authState, dispatch }}>
      { children }
    </AuthContext.Provider>
  );
};

const AuthConsumer = AuthContext.Consumer;

export { AuthContext, AuthProvider, AuthConsumer };
