import React from 'react';
import { NavLink } from 'react-router-dom';

function NavTabs(props) {
  const user = props.user;

  return (
    <ul className="nav nav-tabs mb-4">
      <li className="nav-item">
        <NavLink className="nav-link" to={`/admin/users/${user.id}/edit`}>Edit</NavLink>
      </li>
      {user.user_type !== 'user' &&
      <li className="nav-item">
        <NavLink className="nav-link" to={`/admin/users/${user.id}/companies`}>Companies</NavLink>
      </li>
      }
    </ul>
  );
}

export default NavTabs;
