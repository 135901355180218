import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';

import Button from '../../../../../components/button';
import useForm from '../../../../../hooks/useForm';
import validate from '../shared/companyModuleFormValidation';
import { AuthContext } from '../../../../../contexts/AuthContext';
import { createCompanyModule } from '../../../../../services/companyModule';

function CompanyModuleForm(props) {
  const companyId = props.companyId;
  const modules = props.modules;
  const initialState = {
    companyId: companyId,
    moduleId: ''
  };
  const { values, handleChange, handleSubmit, isButtonLoading, setIsButtonLoading, errors } = useForm(initialState, handleCreateCompanyModule, validate);
  const { dispatch } = useContext(AuthContext);

  function handleCreateCompanyModule() {
    createCompanyModule(values)
      .then(data => {
        if (data.success) {
          setTimeout(() => {
            setIsButtonLoading(false);

            props.history.push({
              pathname: '/admin/companies/' + companyId + '/modules',
              state: {
                stateAlert: {
                  text: 'The module has been created.'
                }
              }
            });
          }, 1000 / 1);
        } else {
          dispatch({type: 'httpStatusCode', httpStatusCode: data.code});
        }
      });
  }

  return (
    <form onSubmit={handleSubmit} noValidate>
      <fieldset>
        <legend>New Company Module</legend>
        <div className="form-group row">
          <label htmlFor="moduleId" className="col-sm-3 col-form-label">Module</label>
          <div className="col-sm-6">
            <select id="moduleId" name="moduleId" value={values.moduleId} onChange={handleChange} className={`form-control ${errors.moduleId && 'is-invalid'}`}>
              <option value="">Please Select</option>
              {modules.map((module, index) => (
              <option key={index} value={module.id}>{module.name}</option>
              ))}
            </select>
            {errors.moduleId && (
              <div className="invalid-feedback">
                {errors.moduleId}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-6 offset-sm-3">
            <Button isButtonLoading={isButtonLoading} >Submit</Button>
          </div>
        </div>
      </fieldset>
    </form>
  );
}

export default withRouter(CompanyModuleForm);
