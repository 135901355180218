import React from 'react';

import Alert from '../../../components/alert';
import CompanyForm from './CompanyForm';
import HttpStatusCode from '../../../components/http-status-code';
import PageHeader from '../../../components/page-header';
import Sidebar from '../shared/sidebar';
import useAlert from '../../../hooks/useAlert';
import useCompany from '../../../hooks/useCompany';

function EditCompany(props) {
  const { match: { params } } = props;
  const companyId = params.companyId;
  const { company, isCompanyLoading } = useCompany(companyId);
  const header = company.name;
  const { alert, setAlert } = useAlert({});
  
  function handleAlert(alert) {
    setAlert(alert);
  }

  return (
    <React.Fragment>
      <HttpStatusCode prevMatch={props.match} />
      <div className="container">
        <div className="row">
          <div className="col-sm-2">
            <Sidebar companyId={companyId} />
          </div>
          <div className="col-sm-10">
            <PageHeader header={header} />
            <Alert alert={alert} />
            {!isCompanyLoading &&
            <CompanyForm company={company} onAlert={handleAlert} />
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default EditCompany;
