import { useContext, useEffect, useState } from 'react';

import { AuthContext } from '../contexts/AuthContext';
import { getCensus } from '../services/census';

const useCensus = (companyId, censusId) => {
  const [census, setCensus] = useState({});
  const [isCensusLoading, setIsCensusLoading] = useState(true);
  const { dispatch } = useContext(AuthContext);

  useEffect(() => {
    getCensus(companyId, censusId)
      .then(data => {
        if (data.success) {
          setCensus(data.result);
          setIsCensusLoading(false);
        } else {
          dispatch({type: 'httpStatusCode', httpStatusCode: data.code});
        }
      });
  }, [companyId, censusId, dispatch]);

  return {
    census,
    isCensusLoading
  };
}

export default useCensus;
