import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import moment from 'moment';

import Alert from '../../../../components/alert';
import HttpStatusCode from '../../../../components/http-status-code';
import PageHeader from '../../../../components/page-header';
import NavTabs from '../shared/nav-tabs';
import Sidebar from '../../shared/sidebar';
import useAlert from '../../../../hooks/useAlert';
import useModule from '../../../../hooks/useModule';
import useResources from '../../../../hooks/useResources';
import { AuthContext } from '../../../../contexts/AuthContext';
import { deleteResource } from '../../../../services/resource';

function Resources(props) {
  const header = 'Administrator';
  const { match: { params } } = props;
  const moduleId = params.moduleId;
  const { location: { state = {} } } = props;
  const { stateAlert = {} } = state || {};
  const { module } = useModule(moduleId);
  const { resources, setRefresh } = useResources(module.id);
  const { alert, setAlert } = useAlert(stateAlert);
  const { dispatch } = useContext(AuthContext);
  
  function handleDeleteResource(resourceId) {
    deleteResource(module.id, resourceId)
      .then(data => {
        if (data.success) {
          setRefresh(true);
          setAlert({
            text: 'The resource has been deleted.'
          });
        } else {
          dispatch({type: 'httpStatusCode', httpStatusCode: data.code});
        }
      });
  }

  return (
    <React.Fragment>
      <HttpStatusCode prevMatch={props.match} />
      <div className="container">
        <div className="row">
          <div className="col-sm-2">
            <Sidebar />
          </div>
          <div className="col-sm-10">
            <PageHeader header={header} />
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/admin">Administrator</Link></li>
              <li className="breadcrumb-item"><Link to="/admin/modules">Modules</Link></li>
              <li className="breadcrumb-item active">Resources</li>
            </ol>
            <h3>{module.name}</h3>
            <NavTabs moduleId={module.id} />
            <Alert alert={alert} />
            <p>Resources are the pages and functions that make up a module. Module resources are used to set up role permissions.</p>
            {resources.length > 0 ?
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Description</th>
                  <th scope="col">Date</th>
                  <th className="icon text-right"><Link to={`/admin/modules/${module.id}/resources/create`}><button type="button" className="btn btn-info btn-sm">Create</button></Link></th>
                </tr>
              </thead>
              <tbody>
                {resources.map((resource, index) => (
                <tr key={index}>
                  <td><Link to={`/admin/modules/${module.id}/resources/${resource.id}/edit`}>{resource.name}</Link></td>
                  <td>{resource.description}</td>
                  <td>{moment(resource.created_at).format('L')}</td>
                  <td className="text-right"><button type="button" className="btn btn-warning btn-sm" onClick={() => handleDeleteResource(resource.id)}>Delete</button></td>
                </tr>
                ))}
              </tbody>
            </table> :
            <p>No resources found. Click <Link to={`/admin/modules/${module.id}/resources/create`}>here</Link> to create resource.</p>
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Resources;
