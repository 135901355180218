import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';

import Button from '../../../../../components/button';
import useForm from '../../../../../hooks/useForm';
import validate from '../shared/companyRoleUserFormValidation';
import { AuthContext } from '../../../../../contexts/AuthContext';
import { createCompanyRoleUser } from '../../../../../services/companyRoleUser';

function CompanyRoleUserForm(props) {
  const companyId = props.companyId;
  const roleId = props.roleId;
  const { users = {} } = props;
  const initialState = {
    companyUserId: '',
    roleId: roleId
  };

  const { values, handleChange, handleSubmit, isButtonLoading, setIsButtonLoading, errors } = useForm(initialState, handleCreateCompanyRoleUser, validate);
  const { dispatch } = useContext(AuthContext);

  function handleCreateCompanyRoleUser() {
    createCompanyRoleUser(companyId, values)
      .then(data => {
        if (data.success) {
          setTimeout(() => {
            setIsButtonLoading(false);

            props.history.push({
              pathname: '/company/' + companyId + '/roles/' + roleId + '/users',
              state: {
                stateAlert: {
                  text: 'The user has been assigned.'
                }
              }
            });
          }, 1000 / 1);
        } else {
          dispatch({type: 'httpStatusCode', httpStatusCode: data.code});
        }
      });
  }

  return (
    <form onSubmit={handleSubmit} noValidate>
      <fieldset>
        <legend>Assign User</legend>
        <div className="form-group row">
          <label htmlFor="name" className="col-sm-3 col-form-label">User</label>
          <div className="col-sm-6">
            <select id="companyUserId" name="companyUserId" value={values.companyUserId} onChange={handleChange} className={`form-control ${errors.companyUserId && 'is-invalid'}`}>
              <option value="">Please Select</option>
              {users.map((user, index) => (
              <option key={index} value={user.company_user_id}>{user.name}</option>
              ))}
            </select>
            {errors.companyUserId && (
              <div className="invalid-feedback">
                {errors.companyUserId}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-6 offset-sm-3">
            <Button isButtonLoading={isButtonLoading} >Submit</Button>
          </div>
        </div>
      </fieldset>
    </form>
  );
}

export default withRouter(CompanyRoleUserForm);
