import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Censuses from '../pages/company/censuses';
import CompanyRoleUsers from '../pages/company/roles/users';
import CompanyUsers from '../pages/company/users';
import CompanyUserRoles from '../pages/company/users/roles';
import CreateCensus from '../pages/company/censuses/create';
import CreateCompanyRoleUser from '../pages/company/roles/users/create';
import CreateCompanyUser from '../pages/company/users/create';
import CreateCompanyUserRole from '../pages/company/users/roles/create';
import CreateRole from '../pages/company/roles/create';
import Dashboard from '../pages/company/dashboard';
import EditCensus from '../pages/company/censuses/edit';
import EditCompany from '../pages/company/edit';
import EditCompanyUser from '../pages/company/users/edit';
import EditRole from '../pages/company/roles/edit';
import NotFound from '../pages/not-found';
import PrivateCompanyRoute from './PrivateCompanyRoute';
import Roles from '../pages/company/roles';

const CompanyRoutes = () => (
  <Switch>
    <PrivateCompanyRoute exact path='/company/:companyId' component={Dashboard} moduleCode='company' resourceName='company.index' />
    <PrivateCompanyRoute path='/company/:companyId/edit' component={EditCompany} moduleCode='company' resourceName='company.edit' />
    <PrivateCompanyRoute exact path='/company/:companyId/users' component={CompanyUsers} moduleCode='user' resourceName='company.users.index' />
    <PrivateCompanyRoute path='/company/:companyId/users/create' component={CreateCompanyUser} moduleCode='user' resourceName='company.users.create' />
    <PrivateCompanyRoute path='/company/:companyId/users/:userId/edit' component={EditCompanyUser} moduleCode='user' resourceName='company.users.edit' />
    <PrivateCompanyRoute exact path='/company/:companyId/users/:userId/roles' component={CompanyUserRoles} moduleCode='user' resourceName='company.users.roles.index' />
    <PrivateCompanyRoute path='/company/:companyId/users/:userId/roles/create' component={CreateCompanyUserRole} moduleCode='user' resourceName='company.users.roles.create' />
    <PrivateCompanyRoute exact path='/company/:companyId/roles' component={Roles} moduleCode='role' resourceName='company.roles.index' />
    <PrivateCompanyRoute path='/company/:companyId/roles/create' component={CreateRole} moduleCode='role' resourceName='company.roles.create' />
    <PrivateCompanyRoute path='/company/:companyId/roles/:roleId/edit' component={EditRole} moduleCode='role' resourceName='company.roles.edit' />
    <PrivateCompanyRoute exact path='/company/:companyId/roles/:roleId/users' component={CompanyRoleUsers} moduleCode='role' resourceName='company.roles.users.index' />
    <PrivateCompanyRoute path='/company/:companyId/roles/:roleId/users/create' component={CreateCompanyRoleUser} moduleCode='role' resourceName='company.roles.users.create' />
    <PrivateCompanyRoute exact path='/company/:companyId/censuses' component={Censuses} moduleCode='census' resourceName='company.censuses.index' />
    <PrivateCompanyRoute path='/company/:companyId/censuses/create' component={CreateCensus} moduleCode='census' resourceName='company.censuses.create' />
    <PrivateCompanyRoute path='/company/:companyId/censuses/:censusId/edit' component={EditCensus} moduleCode='census' resourceName='company.censuses.edit' />
    <Route component={NotFound} />
  </Switch>
)

export default CompanyRoutes
