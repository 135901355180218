import { useContext, useEffect, useState } from 'react';

import { AuthContext } from '../contexts/AuthContext';
import { getResources } from '../services/resource';

const useResources = (moduleId) => {
  const [resources, setResources] = useState([]);
  const [isResourcesLoading, setIsResourcesLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const { dispatch } = useContext(AuthContext);

  useEffect(() => {
    getResources(moduleId)
      .then(data => {
        if (data.success) {
          setResources(data.result);
          setIsResourcesLoading(false);
          setRefresh(false);
        } else {
          dispatch({type: 'httpStatusCode', httpStatusCode: data.code});
        }
      });
  }, [moduleId, refresh, dispatch]);

  return {
    resources,
    isResourcesLoading,
    setRefresh
  };
}

export default useResources;
