import React from 'react';
import { Link } from 'react-router-dom';

import Alert from '../../../../components/alert';
import HttpStatusCode from '../../../../components/http-status-code';
import NavTabs from '../shared/nav-tabs';
import PageHeader from '../../../../components/page-header';
import Sidebar from '../../shared/sidebar';
import UserForm from './UserForm';
import useAlert from '../../../../hooks/useAlert';
import useUser from '../../../../hooks/useUser';

function EditUser(props) {
  const header = 'Administrator';
  const { match: { params } } = props;
  const userId = params.userId;
  const { user, isUserLoading } = useUser(userId);
  const { alert, setAlert } = useAlert({});

  function handleAlert(alert) {
    setAlert(alert);
  }

  return (
    <React.Fragment>
      <HttpStatusCode prevMatch={props.match} />
      <div className="container">
        <div className="row">
          <div className="col-sm-2">
            <Sidebar />
          </div>
          <div className="col-sm-10">
            <PageHeader header={header} />
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/admin">Administrator</Link></li>
              <li className="breadcrumb-item"><Link to="/admin/users">Users</Link></li>
              <li className="breadcrumb-item active">Edit</li>
            </ol>
            <h3>{ user.name }</h3>
            <NavTabs user={user} />
            <Alert alert={alert} />
            {!isUserLoading &&
            <UserForm user={user} onAlert={handleAlert} />
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default EditUser;
