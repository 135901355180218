import { useContext, useEffect, useState } from 'react';

import { AuthContext } from '../contexts/AuthContext';
import { getUser } from '../services/user';

const useUser = (userId) => {
  const [user, setUser] = useState({});
  const [isUserLoading, setIsUserLoading] = useState(true);
  const { dispatch } = useContext(AuthContext);

  useEffect(() => {
    getUser(userId)
      .then(data => {
        if (data.success) {
          setUser(data.result);
          setIsUserLoading(false);
        } else {
          dispatch({type: 'httpStatusCode', httpStatusCode: data.code});
        }
      });
  }, [userId, dispatch]);

  return {
    user,
    isUserLoading
  };
}

export default useUser;
