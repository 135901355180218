import { useContext, useEffect, useState } from 'react';

import { AuthContext } from '../contexts/AuthContext';
import { checkResetPasswordToken } from '../services/auth';

const useCheckResetPasswordToken = (token) => {
  const [isResetPasswordTokenValid, setIsResetPasswordTokenValid] = useState(false);
  const [isResetPasswordTokenLoading, setIsResetPasswordTokenLoading] = useState(true);
  const { dispatch } = useContext(AuthContext);

  useEffect(() => {
    if (token) {
      checkResetPasswordToken(token)
        .then(data => {
          if (data.success) {
            setIsResetPasswordTokenValid(true);
            setIsResetPasswordTokenLoading(false);
          } else {
            if (data.code === 404) {
              setIsResetPasswordTokenLoading(false);
            } else {
              dispatch({type: 'httpStatusCode', httpStatusCode: data.code});
            }
          }
        });
    }
  }, [token, dispatch]);

  return {
    isResetPasswordTokenValid,
    isResetPasswordTokenLoading
  };
}

export default useCheckResetPasswordToken;
