import { useContext, useEffect, useState } from 'react';

import { AuthContext } from '../contexts/AuthContext';
import { getCompany } from '../services/company';

const useCompany = (companyId) => {
  const [company, setCompany] = useState({});
  const [isCompanyLoading, setIsCompanyLoading] = useState(true);
  const { dispatch } = useContext(AuthContext);

  useEffect(() => {
    getCompany(companyId)
      .then(data => {
        if (data.success) {
          setCompany(data.result);
          setIsCompanyLoading(false);
        } else {
          dispatch({type: 'httpStatusCode', httpStatusCode: data.code});
        }
      });
  }, [companyId, dispatch]);

  return {
    company,
    isCompanyLoading
  };
}

export default useCompany;
