import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import moment from 'moment';

import Alert from '../../../../components/alert';
import HttpStatusCode from '../../../../components/http-status-code';
import NavTabs from '../shared/nav-tabs';
import PageHeader from '../../../../components/page-header';
import Sidebar from '../../shared/sidebar';
import useAlert from '../../../../hooks/useAlert';
import useCompany from '../../../../hooks/useCompany';
import useCompanyRoleUsers from '../../../../hooks/useCompanyRoleUsers';
import useRole from '../../../../hooks/useRole';
import { AuthContext } from '../../../../contexts/AuthContext';
import { deleteCompanyRoleUser } from '../../../../services/companyRoleUser';

function CompanyRoleUsers(props) {
  const { match: { params } } = props;
  const companyId = params.companyId;
  const roleId = params.roleId;
  const { company } = useCompany(companyId);
  const { role } = useRole(companyId, roleId);
  const { users, setRefresh } = useCompanyRoleUsers(companyId, roleId);
  const { alert, setAlert } = useAlert({});
  const { dispatch } = useContext(AuthContext);
  const header = company.name + ' - ' + company.city + ', ' + company.state;

  function handleDeleteCompanyRoleUser(companyUserId) {
    deleteCompanyRoleUser(company.id, role.id, companyUserId)
      .then(data => {
        if (data.success) {
          setRefresh(true);
          setAlert({
            text: 'The user has been removed.'
          });
        } else {
          dispatch({type: 'httpStatusCode', httpStatusCode: data.code});
        }
      });
  }

  return (
    <React.Fragment>
      <HttpStatusCode prevMatch={props.match} />
      <div className="container">
        <div className="row">
          <div className="col-sm-2">
            <Sidebar companyId={company.id} />
          </div>
          <div className="col-sm-10">
            <PageHeader header={header} />
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to={`/company/${company.id}`}>Company</Link></li>
              <li className="breadcrumb-item"><Link to={`/company/${company.id}/roles`}>Roles</Link></li>
              <li className="breadcrumb-item active">Users</li>
            </ol>
            <h3>{role.name}</h3>
            <NavTabs companyId={company.id} roleId={role.id} />
            <Alert alert={alert} />
            <p>Users assigned to this role.</p>
            {users.length > 0 ?
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Date</th>
                  <th className="icon text-right"><Link to={`/company/${company.id}/roles/${role.id}/users/create`}><button type="button" className="btn btn-info btn-sm">Create</button></Link></th>
                </tr>
              </thead>
              <tbody>
                {users.map((user, index) => (
                <tr key={index}>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{moment(user.created_at).format('L')}</td>
                  <td className="text-right"><button type="button" className="btn btn-warning btn-sm" onClick={() => handleDeleteCompanyRoleUser(user.company_user_id)}>Remove</button></td>
                </tr>
                ))}
              </tbody>
            </table> :
            <p>No role users found. Click <Link to={`/company/${company.id}/roles/${role.id}/users/create`}>here</Link> to assign user.</p>
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CompanyRoleUsers;
