import React from 'react';

function PageHeader(props) {
  return (
    <div className="pt-3 pb-2 mb-3">
      <h1>{props.header}</h1>
    </div>
  );
}

export default PageHeader;
