import { useCallback, useEffect, useState } from 'react';

import useRecaptcha from './useRecaptcha';

/**
 * validate = null for form without validation (LoginForm.js)
 * recaptchaAction = null for form without recaptcha
 */ 
const useForm = (initialState, handleCallback, validate = null, recaptchaAction = null) => {
  const [values, setValues] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);  //  prevent form submit on render
  const { recaptchaToken, isRecaptchaLoading } = useRecaptcha(recaptchaAction, canSubmit);
  const callback = useCallback(handleCallback, [errors]);

  const handleChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (target.type === 'checkbox' && target.multiple) {    // checked for group checkboxes
      const nodeLists = document.getElementsByName(name);
      const nodeListValues = [];

      if (nodeLists.length > 1) {
        Array.from(nodeLists).forEach((nodeList, index) => {
          if (nodeList.checked) {
            nodeListValues.push(parseInt(nodeList.value));
          }
        });
      }

      setValues({...values, [name]: nodeListValues});
    } else {
      setValues({...values, [name]: value});
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormRecaptcha(); // call setFormRecaptcha() here to prevent async issues of values.recaptchaToken not been set if called below setCanSubmit(true) 

    if (validate) {
      await validate(values).then(data => {
        setErrors(data);
      });
    } else {
      setErrors({});    // for form without validation to trigger useEffect(), setErrors to empty object again
    }

    setCanSubmit(true);  // allow form submit
  };

  const setFormRecaptcha = () => {
    if (recaptchaAction && !isRecaptchaLoading) {
      setValues({...values, 'recaptchaToken': recaptchaToken});
    }
  };

  const resetForm = (newInitialState = initialState) => {
    setValues({...newInitialState});
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && canSubmit) {
      setIsButtonLoading(true);
      callback();
    }

    if (canSubmit) {
      return () => setCanSubmit(false);
    }
  }, [errors, canSubmit, callback]);

  return {
    values,
    handleChange,
    handleSubmit,
    isButtonLoading,
    setIsButtonLoading,
    errors,
    resetForm
  };
}

export default useForm;
