import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { AuthConsumer } from '../contexts/AuthContext';

const PrivateAdminRoute = ({component: Component, ...rest}) => (
  <AuthConsumer>
    {({ authState }) => (
      <Route
        {...rest}
        render={props =>
          authState.isLoggedIn && authState.userType === 'admin' ? (
            <Component {...props} />
          ) : (
            <Redirect to='/user' />
          )
        }
      />
      )
    }
  </AuthConsumer>
)

export default PrivateAdminRoute
