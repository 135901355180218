import { getHeaders, logError, readResponseAsJSON, validateResponse } from '../utils/fetch';

export function createCompanyUserRole(companyId, body) {
  const headers = getHeaders();
  const options = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: headers
  };

  return fetch('/api/companies/' + companyId + '/users/' + body.companyUserId + '/roles', options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}

export function getCompanyUserRoles(companyId, userId) {
  const headers = getHeaders();
  const options = {
    method: 'GET',
    headers: headers
  };

  return fetch('/api/companies/' + companyId + '/users/' + userId + '/roles', options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}

export function deleteCompanyUserRole(companyId, userId, roleId, companyUserId) {
  const headers = getHeaders();
  const options = {
    method: 'DELETE',
    headers: headers
  };

  return fetch('/api/companies/' + companyId + '/users/' + userId + '/roles/' + roleId + '/' + companyUserId, options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}
