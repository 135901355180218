import React from 'react';
import { withRouter } from 'react-router-dom';

import Button from '../../../components/button';
import useForm from '../../../hooks/useForm';
import validate from './resetPasswordFormValidation';
import { resetPassword } from '../../../services/auth';

function ResetPasswordForm(props) {
  const token = props.token;
  const initialState = {
    password: '',
    confirmPassword: '',
    token: token
  };
  const { values, handleChange, handleSubmit, isButtonLoading, setIsButtonLoading, errors, resetForm } = useForm(initialState, handleResetPassword, validate);

  function handleResetPassword() {
    resetPassword(values)
      .then(data => {
        setTimeout(() => {
          setIsButtonLoading(false);

          if (data.success) {
            props.onAlert({
              text: 'Your password has been changed.'
            });

            resetForm();
          } else {
            props.history.push({
              pathname: '/password/forgot',
              state: {
                stateAlert: {
                  text: 'The reset password token is invalid or expired.',
                  type: 'danger'
                }
              }
            });
          }
        }, 1000 / 1);
      });
  }

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="form-group row">
        <label htmlFor="name" className="col-sm-3 col-form-label">Password</label>
        <div className="col-sm-6">
          <input type="password" id="password" name="password" value={values.password} onChange={handleChange} className="form-control" />
        </div>
      </div>
      <div className="form-group row">
        <label htmlFor="email" className="col-sm-3 col-form-label">Confirm Password</label>
        <div className="col-sm-6">
          <input type="password" id="confirmPassword" name="confirmPassword" value={values.confirmPassword} onChange={handleChange} className={`form-control ${errors.password && 'is-invalid'}`} />
          {errors.confirmPassword && (
            <div className="invalid-feedback">
              {errors.confirmPassword}
            </div>
          )}
        </div>
      </div>
      <div className="form-group row">
        <div className="col-sm-6 offset-sm-3">
          <Button isButtonLoading={isButtonLoading} >Submit</Button>
        </div>
      </div>
    </form>
  );
}

export default withRouter(ResetPasswordForm);
