import React from 'react';

import Button from '../../components/button';
import useForm from '../../hooks/useForm';
import validate from './activationFormValidation';
import { resendActivationEmail } from '../../services/auth';

function ActivationForm(props) {
  const initialState = {
    email: ''
  };
  const { values, handleChange, handleSubmit, isButtonLoading, setIsButtonLoading, errors, resetForm } = useForm(initialState, handleResendActivationEmail, validate);

  function handleResendActivationEmail() {
    resendActivationEmail(values.email)
      .then(data => {
        setTimeout(() => {
          setIsButtonLoading(false);

          if (data.success) {
            props.onAlert({
              text: 'We have emailed your account activation link!'
            });

            resetForm();
          } else {
            props.onAlert({
              text: 'We can\'t find a user with that email address that requires activation.',
              type: 'danger'
            });
          }
        }, 1000 / 1);
      });
  }

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="form-group col-sm-6 offset-sm-3">
        <p>If you did not receive an activation email or if the token is expired, enter your email address to request another.</p>
        <input type="email" id="email" name="email" value={values.email} placeholder="Email" onChange={handleChange} className={`form-control ${errors.email && 'is-invalid'}`} />
        {errors.email && (
          <div className="invalid-feedback">
            {errors.email}
          </div>
        )}
      </div>
      <div className="form-group col-sm-6 offset-sm-3">
        <Button isButtonLoading={isButtonLoading} >Send</Button>
      </div>
    </form>
  );
}

export default ActivationForm;
