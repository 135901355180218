import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import PageHeader from '../../../components/page-header';
import Sidebar from '../shared/sidebar';
import useUserCompanies from '../../../hooks/useUserCompanies';
import { AuthContext } from '../../../contexts/AuthContext';

function UserCompanies() {
  const header = 'Manage Companies';
  const { authState } = useContext(AuthContext);
  const { companies } = useUserCompanies(authState.id);

  return (
    <React.Fragment>
      <div className="container">
        <div className="row">
          <div className="col-sm-2">
            <Sidebar />
          </div>
          <div className="col-sm-10">
            <PageHeader header={header} />
            {companies.length > 0 ?
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Location</th>
                  <th className="icon" scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {companies.map((company, index) => (
                <tr key={index}>
                  <td><Link to={`/company/${company.id}`}>{company.name}</Link></td>
                  <td>{company.city}, {company.state}</td>
                  <td className="text-right"><Link to={`/company/${company.id}`}><i aria-label="Dashboard" className="fas fa-tachometer-alt" title="Dashboard" aria-hidden></i></Link></td>
                </tr>
                ))}
              </tbody>
            </table> :
            <p>No companies found.</p>
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default UserCompanies;
