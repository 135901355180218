import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import moment from 'moment';

import Alert from '../../../../components/alert';
import HttpStatusCode from '../../../../components/http-status-code';
import NavTabs from '../shared/nav-tabs';
import PageHeader from '../../../../components/page-header';
import Sidebar from '../../shared/sidebar';
import useAlert from '../../../../hooks/useAlert';
import useUser from '../../../../hooks/useUser';
import useUserCompanies from '../../../../hooks/useUserCompanies';
import { AuthContext } from '../../../../contexts/AuthContext';
import { deleteUserCompany } from '../../../../services/userCompany';

function UserCompanies(props) {
  const header = 'Administrator';
  const { match: { params } } = props;
  const userId = params.userId;
  const { location: { state = {} } } = props;
  const { stateAlert = {} } = state || {};
  const { user } = useUser(userId);
  const { companies, setRefresh } = useUserCompanies(userId);
  const { alert, setAlert } = useAlert(stateAlert);
  const { dispatch } = useContext(AuthContext);

  function handleDeleteUserCompany(companyId) {
    deleteUserCompany(user.id, companyId)
      .then(data => {
        if (data.success) {
          setRefresh(true);
          setAlert({
            text: 'The company has been removed.'
          });
        } else {
          dispatch({type: 'httpStatusCode', httpStatusCode: data.code});
        }
      });
  }

  return (
    <React.Fragment>
      <HttpStatusCode prevMatch={props.match} />
      <div className="container">
        <div className="row">
          <div className="col-sm-2">
            <Sidebar />
          </div>
          <div className="col-sm-10">
            <PageHeader header={header} />
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/admin">Administrator</Link></li>
              <li className="breadcrumb-item"><Link to="/admin/users">Users</Link></li>
              <li className="breadcrumb-item active">Companies</li>
            </ol>
            <h3>{user.name}</h3>
            <NavTabs user={user} />
            <Alert alert={alert} />
            <p>A business user can manage many companies.</p>
            {companies.length > 0 ?
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Location</th>
                  <th scope="col">Administrator</th>
                  <th scope="col">Date</th>
                  <th className="icon text-right"><Link to={`/admin/users/${user.id}/companies/create`}><button type="button" className="btn btn-info btn-sm">Create</button></Link></th>
                </tr>
              </thead>
              <tbody>
                {companies.map((company, index) => (
                <tr key={index}>
                  <td>{company.name}</td>
                  <td>{company.city}, {company.state}</td>
                  <td>{company.is_admin? 'Yes' : 'No'}</td>
                  <td>{moment(company.created_at).format('L')}</td>
                  <td className="text-right"><button type="button" className="btn btn-warning btn-sm" onClick={() => handleDeleteUserCompany(company.id)}>Remove</button></td>
                </tr>
                ))}
              </tbody>
            </table> :
            <p>No companies found. Click <Link to={`/admin/users/${user.id}/companies/create`}>here</Link> to assign company.</p>
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default UserCompanies;
