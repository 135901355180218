import { getHeaders, logError, readResponseAsJSON, validateResponse } from '../utils/fetch';

export function createModule(body) {
  const headers = getHeaders();
  const options = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: headers
  };

  return fetch('/api/modules', options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}

export function getModules() {
  const headers = getHeaders();
  const options = {
    method: 'GET',
    headers: headers
  };

  return fetch('/api/modules', options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}

export function getModule(moduleId) {
  const headers = getHeaders();
  const options = {
    method: 'GET',
    headers: headers
  };

  return fetch('/api/modules/' + moduleId, options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}

export function updateModule(moduleId, body) {
  const headers = getHeaders();
  const options = {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: headers
  };

  return fetch('/api/modules/' + moduleId, options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}
