import React from 'react';

function Alert(props) {
  const alert = props.alert;
  const types = {
    danger: 'danger',
    info: 'info',
    light: 'light',
    primary: 'primary',
    secondary: 'secondary',
    success: 'success',
    warning: 'warning'
  };
  const alertTypeClass = types[alert.type] || types.success;
  const alertClassName = `alert alert-dismissible alert-${alertTypeClass}`;

  return (
    <React.Fragment>
    {alert.text &&
    <div className={alertClassName}>
      <button type="button" className="close" data-dismiss="alert">&times;</button>
      {alert.text}
    </div>
    }
    </React.Fragment>
  );
}

export default Alert;
