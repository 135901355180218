import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import Alert from '../../../components/alert';
import PageHeader from '../../../components/page-header';
import Sidebar from '../shared/sidebar';
import useAlert from '../../../hooks/useAlert';
import useUserCompanies from '../../../hooks/useUserCompanies';
import { AuthContext } from '../../../contexts/AuthContext';

function Dashboard(props) {
  const { location: { state = {} } } = props;
  const { stateAlert = {} } = state || {};
  const { authState } = useContext(AuthContext);
  const { companies } = useUserCompanies(authState.id);
  const header = authState.name;
  const { alert } = useAlert(stateAlert);

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-2">
          <Sidebar />
        </div>
        <div className="col-sm-10">
          <PageHeader header={header} />
          <Alert alert={alert} />
          <div className="row">
              <div className="col-md-6 col-sm-8">
                  {authState.userType === 'company' && (
                  <div className="dashboard-card">
                      <div className="heading">
                          <Link to="/user/companies" className="float-right">View all</Link>
                          <h4 className="title">Manage Companies</h4>
                          <p className="category">List of companies you are managing.</p>
                      </div>
                      <div className="content">
                          {companies.length > 0 ? (
                          <table className="table table-striped">
                              <tbody>
                                {companies.map((company, index) => (
                                <tr key={index}>
                                  <td><Link to={`/company/${company.id}`}>{company.name}</Link></td>
                                  <td>{company.city}, {company.state}</td>
                                  <td className="text-right"><Link to={`/company/${company.id}`}><i aria-label="Dashboard" className="fas fa-tachometer-alt" title="Dashboard" aria-hidden></i></Link></td>
                                </tr>
                                ))}
                              </tbody>
                          </table>) : (
                            <p>No companies found.</p>
                          )}
                      </div>
                  </div>
                  )}
              </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
