import React from 'react';
import { Link } from 'react-router-dom';

import CompanyModuleForm from './CompanyModuleForm';
import HttpStatusCode from '../../../../../components/http-status-code';
import PageHeader from '../../../../../components/page-header';
import Sidebar from '../../../shared/sidebar';
import useCompany from '../../../../../hooks/useCompany';
import useModules from '../../../../../hooks/useModules';

function CreateCompanyModule(props) {
  const header = 'Administrator';
  const { match: { params } } = props;
  const companyId = params.companyId;
  const { company, isCompanyLoading } = useCompany(companyId);
  const { modules, isModulesLoading } = useModules();

  return (
    <React.Fragment>
      <HttpStatusCode prevMatch={props.match} />
      <div className="container">
        <div className="row">
          <div className="col-sm-2">
            <Sidebar />
          </div>
          <div className="col-sm-10">
            <PageHeader header={header} />
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/admin">Administrator</Link></li>
              <li className="breadcrumb-item"><Link to="/admin/companies">Companies</Link></li>
              <li className="breadcrumb-item"><Link to={`/admin/companies/${company.id}/modules`}>Modules</Link></li>
              <li className="breadcrumb-item active">Create</li>
            </ol>
            <h3>{company.name}</h3>
            <p>A module is a collection of functions providing additional functionality.</p>
            {(!isCompanyLoading && !isModulesLoading) &&
            <CompanyModuleForm companyId={company.id} modules={modules} />
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CreateCompanyModule;
