import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';

import moment from 'moment';

import { CompanyContext } from '../../../../contexts/CompanyContext';
import { hasCompanyModule } from '../../../../utils/helper';

function Sidebar(props) {
  const companyId = props.companyId;
  const { companyModules } = useContext(CompanyContext);
  const today = Date.now();

  return (
    <div className="sidebar sidebar-box">
      <div className="user">
        <div className="info">{ moment(today).format('MMM DD YYYY') }</div>
      </div>
      <ul className="nav flex-column">
        <li className="nav-item">
          <NavLink className="nav-link" exact to={`/company/${companyId}`}><i className="fas fa-tachometer-alt fa-fw" aria-hidden></i> Dashboard</NavLink>
        </li>
        {hasCompanyModule(companyModules, 'census') &&
        <li className="nav-item">
          <NavLink className="nav-link" to={`/company/${companyId}/censuses`}><i className="fas fa-flag-usa fa-fw" aria-hidden></i> Censuses</NavLink>
        </li>
        }
        <li className="nav-item">
          <a data-toggle="collapse" href="#collapse-settings">
            <i className="fas fa-cog fa-fw" aria-hidden></i> Settings
            <span className="caret"></span>
          </a>
          <div className="submenu collapse" id="collapse-settings">
            <ul className="nav flex-column">
              {hasCompanyModule(companyModules, 'user') &&
              <li className="nav-item">
                <NavLink className="nav-link" to={`/company/${companyId}/users`}>Users</NavLink>
              </li>
              }
              {hasCompanyModule(companyModules, 'role') &&
              <li className="nav-item">
                <NavLink className="nav-link" to={`/company/${companyId}/roles`}>Roles</NavLink>
              </li>
              }
              {hasCompanyModule(companyModules, 'company') &&
              <li className="nav-item">
                <NavLink className="nav-link" to={`/company/${companyId}/edit`}>Company</NavLink>
              </li>
              }
              {hasCompanyModule(companyModules, 'token') &&
              <li className="nav-item">
                <NavLink className="nav-link" to={`/company/${companyId}/token`}>API Token</NavLink>
              </li>
              }
            </ul>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default Sidebar;
