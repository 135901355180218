import { checkEmail } from '../../services/auth';

// using async/await to handle asynchronous checkEmail to get data and prevent empty errors.email on return
export default async function validate(values) {
  let errors = {};

  if (!values.name) {
    errors.name = 'Name is required';
  }

  if (!values.email) {
    errors.email = 'Email address is required';
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email address is invalid';
  } else {
    await checkEmail(values.email)
      .then(data => {
        if (!data.success) {
          errors.email = 'Email is already taken';
        }
      });
  }
  
  if (values.confirmEmail !== values.email) {
    errors.confirmEmail = 'Email does not match';
  }

  if (!values.password) {
    errors.password = 'Password is required';
  } else if (values.password.length < 8) {
    errors.password = 'Password must be at least 8 characters';
  }

  return errors;
};
