import { getHeaders, logError, readResponseAsJSON, validateResponse } from '../utils/fetch';

export function createCompanyUser(body) {
  const headers = getHeaders();
  const options = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: headers
  };

  return fetch('/api/companies/' + body.companyId + '/users', options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}

export function getCompanyUsers(companyId) {
  const headers = getHeaders();
  const options = {
    method: 'GET',
    headers: headers
  };

  return fetch('/api/companies/' + companyId + '/users', options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}

export function getCompanyUser(companyId, userId) {
  const headers = getHeaders();
  const options = {
    method: 'GET',
    headers: headers
  };

  return fetch('/api/companies/' + companyId + '/users/' + userId, options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}

export function updateCompanyUser(companyId, userId, body) {
  const headers = getHeaders();
  const options = {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: headers
  };

  return fetch('/api/companies/' + companyId + '/users/' + userId, options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}

export function deleteCompanyUser(companyId, userId) {
  const headers = getHeaders();
  const options = {
    method: 'DELETE',
    headers: headers
  };

  return fetch('/api/companies/' + companyId + '/users/' + userId, options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}
