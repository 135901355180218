import React from 'react';
import { Link } from 'react-router-dom';

import PageHeader from '../../components/page-header';

function Verify() {
  const header = 'Verify Email Address';

  return (
    <div className="container">
      <PageHeader header={header} />
      <p>You have successfully registered! Before proceeding, please check your email for a activation link. If you did not receive the email, <Link to="/activation">click here to request another</Link>.</p>
    </div>
  );
}

export default Verify;
