import React from 'react';
import { NavLink } from 'react-router-dom';

function NavTabs(props) {
  const companyId = props.companyId;
  const userId = props.userId;

  return (
    <ul className="nav nav-tabs mb-4">
      <li className="nav-item">
        <NavLink className="nav-link" to={`/company/${companyId}/users/${userId}/edit`}>Edit</NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to={`/company/${companyId}/users/${userId}/roles`}>Roles</NavLink>
      </li>
    </ul>
  );
}

export default NavTabs;
