import React from 'react';

import PageHeader from '../../../components/page-header';

function Privacy() {
  const header = 'Privacy Policy';

  return (
    <div className="container">
      <PageHeader header={header} />
      <p>This Privacy Policy describes policies and procedures relating to the use, collection and disclosure of your information in connection with your use of this website, Correspondence, and mobile application (&ldquo;Site&rdquo;). The terms &ldquo;us&rdquo;, &ldquo;we&rdquo;, &ldquo;our&rdquo;, and C refer to Codenology (&ldquo;C&rdquo;), a program owned and operated by Codenology, LLC (&ldquo;CL&rdquo;) under California Law. When using our Site, you agree to our collecting using, and disclosing your information as described in the terms of this Policy.</p>
      <ol style={{ listStyleType: 'decimal' }}>
          <li>
              <h3>Information You Submit</h3>

              <p>We may store information you submit to or through the Site. We may use the information to provide customer support, send you messages, back-up our systems, allow for disaster discovery, comply with all legal obligations and any other purpose described in this Privacy Policy or the Term of Service.</p>
              <ol style={{ listStyleType: 'decimal' }}>
                  <li><strong>Account Registration:</strong> <p>You are required to provide a full name and email address to establish an account. You are allowed to manage your email preferences and modify certain information connected to your account. You are not allowed to opt out of receiving necessary administrative and/or legal notices. If you are aware of an unauthorized account created in your name and/or depicting you, you may request the removal of that account.</p></li>
                  <li><strong>Public Content:</strong> <p>Information submitted will become part of a database. You therefore assume the risk that what you submit may be turned over to prosecutors and/or other law enforcement officials if CL is lawfully compelled by a government issued subpoena and/or court order.</p></li>
                  <li><strong>Messaging:</strong> <p>All messages sent through the Site will be stored on our server. Correspondence may be turned over to prosecutors and/or other law enforcement officials if CL is lawfully compelled by a government issued subpoena and/or court order. When you send messages through the site, we may log your contact information and the date and time of message processing.</p></li>
                  <li><strong>Credit Card Purchases:</strong> <p>When purchasing credits on the Site, we may collect and store all billing and credit card information. This information will be shared with third parties who perform tasks required to complete the purchase transaction (e.g., fulfilling orders and processing card payments). When you submit credit card information, that information is encrypted with industry standard technology.</p></li>
              </ol>
          </li>
          <li>
              <h3>Information We Collect</h3>
              <p>In addition to information submitted to and through our Site, we store certain information from and about you in the course of your use of our Site. We use this information to analyze and track user behavior, personalize your experience, provide the functionality and improve the quality of the Site, and may use it to display relevant advertising. For instance:</p>
              <ol style={{ listStyleType: 'decimal' }}>
                  <li><strong>Location:</strong> <p>We can store and collect information pertaining to your location if you allow your computer or mobile device to send us location data. You can, perhaps, change the settings on your computer or mobile device to prevent location data from being sent.</p></li>
                  <li><strong>Activity:</strong> <p>We can store and collect information relating to you and your use of our Site, such as browser type, IP address, unique device identifiers, URL requests and referring URL, browser language, pages viewed, and date and times of your visit to the Site.</p></li>
                  <li><strong>Cookies:</strong> <p>We may use cookies, local shared objects or flash cookies, web beacons, and other related technology regarding your use of our Site (&ldquo;Cookies&rdquo;). Cookies can have unique identifiers, and reside in your computer, emails sent, our Site. Cookies may transmit to us information about you and your use of our Site, i.e., your browser, preferences, data relating to advertisements that are displayed to you or that you have clicked on, and date and time of usage. You can disable some Cookies on your device or browser settings, although doing so may perhaps affect your use of the Site.</p></li>
              </ol>
          </li>
          <li>
              <h3>Third Parties</h3>
              <p>We work with certain third parties and they may have access to information from you and/or about you on this site. Please read below for a detailed summation.</p>
              <ol style={{ listStyleType: 'decimal' }}>
                  <li><strong>Advertising:</strong> <p>We allow certain third parties who provide advertising services to use cookies in connection with your use of this Site. They may collect and store this information and use it for the same purposes as described in the C Cookies heading - and they may be able to associate the information they collect with other information they have about you. We do not necessarily have the control or access to the Cookies these third parties use. However, you may be able to opt-out of some of their practices by visiting Omniture, Aperture, Privacy Choice and Network Advertising Initiative. Opting out does not prevent you from receiving any advertisements.</p></li>
                  <li><strong>Service Providers:</strong> <p>We can outsource some of our technical and customer support, reporting and tracking functions, quality assurance, testing, payment processing functions, and other services to third parties. And we may share information pertaining to you with these parties so that they may perform their services.</p></li>
                  <li><strong>Aggregate Information:</strong> <p>We may share all user information in the aggregate with third parties, i.e., with advertisers and/or content distributors. For instance, we may - from time to time - disclose the number of users that have been exposed to or who have clicked on advertisements.</p></li>
                  <li><strong>Business Transfers:</strong> <p>We may also share information from and about you with joint ventures, companies under common control, and/or subsidiaries, in which event, we will require that they honor this Privacy Policy.</p></li>
                  <li><strong>Investigations:</strong> <p>We may investigate and/or disclose information from and/or about you if we have a good faith belief that an investigation or disclosure is (a) reasonably necessary to comply with legal process and law enforcement instructions and orders, such as a search warrant,
    subpoena, statute, judicial proceedings or other legal process served on us; (b) helpful to investigate, identify, or prevent possible wrongdoing associate with the Site; and (c) protect our reputation, property, rights, or that of our users, user affiliates and/or the public.</p></li>
                  <li><strong>Links:</strong> <p>The Site may have links to unaffiliated third party websites. Except as is set forth herein, we do not share your personal information with them, nor are we responsible for their privacy practices. Please read the privacy policy on all other linked third party sites.</p></li>
              </ol>
          </li>
          <li>
              <h3>Data Retention and Account Termination</h3>
              <p>Information obtained from and/or about you will be processed and stored in the United States of America. We can keep that information as long as is permitted or required under the law. Although you may terminate your account with us, we will remove your account but save your data in our systems in order to safeguard our ability to satisfy authorized uses of this Privacy Policy. For instance, we may use retained information to prevent, investigate, and/or identify wrongdoing in connection associated with your use or alleged use of this Site, or to comply with any and all legal obligations. Thus, data from and/or about you may be stored even if removed from our active database. That being said, if information sent by you is placed on separate server(s) and/or other storage device(s) by law enforcement officials, we do not know how long they may retain that information. You are allowed to terminate your account with us at any time.</p>
              <p>We will respond to requests relating to personal data sent from within the European Union according to the procedures mandated by the member state from where the request is sent. You will receive a response to your request within 30 days.</p>
          </li>
          <li>
              <h3>Children</h3>
              <p>Our site is intended for general audiences and is not directed to children less than 13 years of age. We do not knowingly collect information from children under 13. If you are aware that a child has provided us with any personal information without parental and/or guardian consent, please contact us. If and when we are made aware that a child under 13 has provided personal information without consent, we will take all necessary steps and actions to remove that information and terminate the child's account.</p>
          </li>
          <li>
              <h3>Security</h3>
              <p>We follow generally accepted industry standards to protect personal information submitted to us, during its transmission and our receipt of it. No method of transmission (via a computer and/or mobile device operating on the internet) or method of electronic data storage is 100% secure. Therefore, although we aim to use commercially acceptable means to protect any and all data submitted on the Site, we cannot guarantee absolute security.</p>
          </li>
          <li>
              <h3>Dispute Resolution</h3>
              <p>If you believe that we have not adhered to our Privacy Policy, you may write to the Privacy Officer at the following address:</p>
              <address>
                  support{/* >@. */}@{/* >@. */}codenology{/* >@. */}.{/* >@. */}com
              </address>
          </li>
          <li>
              <h3>Modifications to This Privacy Policy</h3>
              <p>We may and are allowed to revise the provisions of this Policy from time to time. The most current version of this Privacy Policy will govern any and all use of your information on this Site, and will be located here. If there are material changes made to this Privacy Policy, we will send a notification by email, or will we post a notice on the Site prior to the effective date of any changes made. Further, by continuing to access and/or use this Site after any changes become effective, you agree to be bound by the revised Privacy Policy.</p>
          </li>
      </ol>
      <p>Effective Date: December 14, 2019</p>
    </div>
  );
}

export default Privacy;
