import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';

import moment from 'moment';

import { AuthContext } from '../../../../contexts/AuthContext';

function Sidebar() {
  const today = Date.now();
  const { authState } = useContext(AuthContext);
  const userCompanies = authState.companies;

  return (
    <div className="sidebar sidebar-box">
      <div className="user">
        <div className="info">{ moment(today).format('MMM DD YYYY') }</div>
      </div>
      <ul className="nav flex-column">
        <li className="nav-item">
          <NavLink className="nav-link" exact to="/user"><i className="fas fa-home fa-fw" aria-hidden></i> Home</NavLink>
        </li>
        {typeof userCompanies !== 'undefined' &&
        <li className="nav-item">
          <NavLink className="nav-link" to="/user/companies"><i className="fas fa-building fa-fw" aria-hidden></i> Companies</NavLink>
        </li>
        }
      </ul>
    </div>
  );
}

export default Sidebar;
