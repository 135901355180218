import React from 'react';

import Alert from '../../../components/alert';
import PageHeader from '../../../components/page-header';
import ForgotPasswordForm from './ForgotPasswordForm';
import useAlert from '../../../hooks/useAlert';

function ForgotPassword(props) {
  const header = 'Forgot your password?';
  const { location: { state = {} } } = props;
  const { stateAlert = {} } = state || {};
  const { alert, setAlert } = useAlert(stateAlert);

  function handleAlert(alert) {
    setAlert(alert);
  }

  return (
    <div className="container">
      <PageHeader header={header} />
      <Alert alert={alert} />
      <ForgotPasswordForm onAlert={handleAlert} />
    </div>
  );
}

export default ForgotPassword;
