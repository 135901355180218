import React from 'react';

import AppRoutes from './routes/AppRoutes';
import Footer from './components/footer';
import Navbar from './components/navbar';
import { AuthProvider } from './contexts/AuthContext';

function App() {
  return (
    <AuthProvider>
      <Navbar />
      <AppRoutes />
      <Footer />
    </AuthProvider>
  );
}

export default App;
