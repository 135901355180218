import React from 'react';
import { NavLink } from 'react-router-dom';

import moment from 'moment';

function Sidebar() {
  const today = Date.now();

  return (
    <div className="sidebar sidebar-box">
      <div className="user">
        <div className="info">{ moment(today).format('MMM DD YYYY') }</div>
      </div>
      <ul className="nav flex-column">
        <li className="nav-item">
          <NavLink className="nav-link" exact to="/admin"><i className="fas fa-tachometer-alt fa-fw" aria-hidden></i> Dashboard</NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to="/admin/companies"><i className="fas fa-building fa-fw" aria-hidden></i> Companies</NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to="/admin/users"><i className="fa fa-users fa-fw" aria-hidden></i> Users</NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to="/admin/modules"><i className="fa fa-cubes fa-fw" aria-hidden></i> Modules</NavLink>
        </li>
      </ul>
    </div>
  );
}

export default Sidebar;
