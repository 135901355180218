import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';

import Button from '../../../../components/button';
import useForm from '../../../../hooks/useForm';
import validate from '../shared/censusFormValidation';
import { AuthContext } from '../../../../contexts/AuthContext';
import { createCensus } from '../../../../services/census';

function CreateCensusForm(props) {
  const { company = {} } = props;
  const companyId = company.id;
  const initialState = {
    url: '',
    name: '',
    description: '',
    companyId: companyId
  };

  const { values, handleChange, handleSubmit, isButtonLoading, setIsButtonLoading, errors } = useForm(initialState, handleCreateCensus, validate);
  const { dispatch } = useContext(AuthContext);

  function handleCreateCensus() {
    createCensus(values)
      .then(data => {
        if (data.success) {
          setTimeout(() => {
            setIsButtonLoading(false);

            props.history.push({
              pathname: '/company/' + companyId + '/censuses',
              state: {
                stateAlert: {
                  text: 'The census has been created.'
                }
              }
            });
          }, 1000 / 1);
        } else {
          setTimeout(() => {
            setIsButtonLoading(false);

            props.onAlert({
              text: 'The request URI is incorrect, or the resource does not exist.',
              type: 'danger'
            });
          }, 1000 / 1);

          dispatch({type: 'httpStatusCode', httpStatusCode: data.code});
        }
      });
  }

  return (
    <form onSubmit={handleSubmit} noValidate>
      <fieldset>
        <legend>New Census</legend>
        <div className="form-group row">
          <label htmlFor="url" className="col-sm-3 col-form-label">API URL</label>
          <div className="col-sm-6">
            <textarea id="url" name="url" value={values.url} onChange={handleChange} rows="10" className={`form-control ${errors.url && 'is-invalid'}`} />
            {errors.url && (
              <div className="invalid-feedback">
                {errors.url}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="name" className="col-sm-3 col-form-label">Table Name</label>
          <div className="col-sm-6">
            <input type="text" id="name" name="name" value={values.name} onChange={handleChange} className={`form-control ${errors.name && 'is-invalid'}`} />
            <small id="nameHelp" className="form-text text-muted">The database table name (e.g. acs5yr_2017_county_dp05). Spaces and special characters will be replace with underscore.</small>
            {errors.name && (
              <div className="invalid-feedback">
                {errors.name}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="description" className="col-sm-3 col-form-label">Description</label>
          <div className="col-sm-6">
            <textarea id="description" name="description" value={values.description} onChange={handleChange} className="form-control" />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-6 offset-sm-3">
            <Button isButtonLoading={isButtonLoading} >Submit</Button>
          </div>
        </div>
      </fieldset>
    </form>
  );
}

export default withRouter(CreateCensusForm);
