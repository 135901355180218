import React from 'react';
import { Link } from 'react-router-dom';

import Alert from '../../../../components/alert';
import HttpStatusCode from '../../../../components/http-status-code';
import ModuleForm from './ModuleForm';
import NavTabs from '../shared/nav-tabs';
import PageHeader from '../../../../components/page-header';
import Sidebar from '../../shared/sidebar';
import useAlert from '../../../../hooks/useAlert';
import useModule from '../../../../hooks/useModule';

function EditModule(props) {
  const header = 'Administrator';
  const { match: { params } } = props;
  const moduleId = params.moduleId;
  const { module, isModuleLoading } = useModule(moduleId);
  const { alert, setAlert } = useAlert({});
  
  function handleAlert(alert) {
    setAlert(alert);
  }

  return (
    <React.Fragment>
      <HttpStatusCode prevMatch={props.match} />
      <div className="container">
        <div className="row">
          <div className="col-sm-2">
            <Sidebar />
          </div>
          <div className="col-sm-10">
            <PageHeader header={header} />
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/admin">Administrator</Link></li>
              <li className="breadcrumb-item"><Link to="/admin/modules">Modules</Link></li>
              <li className="breadcrumb-item active">Edit</li>
            </ol>
            <h3>{ module.name }</h3>
            <NavTabs moduleId={module.id} />
            <Alert alert={alert} />
            {!isModuleLoading &&
            <ModuleForm module={module} onAlert={handleAlert} />
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default EditModule;
