import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';

import Button from '../../../../components/button';
import useForm from '../../../../hooks/useForm';
import validate from './userFormValidation';
import { AuthContext } from '../../../../contexts/AuthContext';
import { updateUser } from '../../../../services/user';

function UserForm(props) {
  const { user = {} } = props;
  const userId = user.id;
  const initialState = {
    name: user.name,
    email: user.email,
    userType: user.user_type,
    disabled: user.disabled
  };

  const { values, handleChange, handleSubmit, isButtonLoading, setIsButtonLoading, errors } = useForm(initialState, handleUpdateUser, validate);
  const { dispatch } = useContext(AuthContext);

  function handleUpdateUser() {
    updateUser(userId, values)
      .then(data => {
        if (data.success) {
          setTimeout(() => {
            setIsButtonLoading(false);

            props.onAlert({
              text: 'The user has been updated.'
            });
          }, 1000 / 1);
        } else {
          dispatch({type: 'httpStatusCode', httpStatusCode: data.code});
        }
      });
  }

  return (
    <form onSubmit={handleSubmit} noValidate>
      <fieldset>
        <legend>Edit User</legend>
        <div className="form-group row">
          <label htmlFor="name" className="col-sm-3 col-form-label">Name</label>
          <div className="col-sm-6">
            <input type="text" id="name" name="name" value={values.name} onChange={handleChange} className={`form-control ${errors.name && 'is-invalid'}`} readOnly />
            {errors.name && (
              <div className="invalid-feedback">
                {errors.name}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="email" className="col-sm-3 col-form-label">Email</label>
          <div className="col-sm-6">
            <input type="input" id="email" name="email" value={values.email} onChange={handleChange} className={`form-control ${errors.email && 'is-invalid'}`} readOnly />
            {errors.email && (
              <div className="invalid-feedback">
                {errors.email}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="userType" className="col-sm-3 col-form-label">Type</label>
          <div className="col-sm-6">
            <select id="userType" name="userType" value={values.userType} onChange={handleChange} className={`form-control ${errors.userType && 'is-invalid'}`}>
              <option value="">Please Select</option>
              <option value="admin">Codenology Administrator</option>
              <option value="company">Company User</option>
              <option value="standard">Standard User</option>
            </select>
            {errors.userType && (
              <div className="invalid-feedback">
                {errors.userType}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-3 text-right">Disabled</div>
          <div className="col-sm-6">
              <div className="form-check">
                  <input type="checkbox" id="disabled" name="disabled" onChange={handleChange} className="form-check-input" checked={values.disabled} />
                  <label htmlFor="disabled" className="form-check-label">Disabled user account</label>
              </div>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-6 offset-sm-3">
            <Button isButtonLoading={isButtonLoading} >Submit</Button>
          </div>
        </div>
      </fieldset>
    </form>
  );
}

export default withRouter(UserForm);
