import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';

import Button from '../../../../components/button';
import useForm from '../../../../hooks/useForm';
import validate from './userFormValidation';
import { AuthContext } from '../../../../contexts/AuthContext';
import { createUser } from '../../../../services/user';

function UserForm(props) {
  const initialState = {
    name: '',
    email: '',
    confirmEmail: '',
    userType: ''
  };
  const { values, handleChange, handleSubmit, isButtonLoading, setIsButtonLoading, errors } = useForm(initialState, handleCreateUser, validate);
  const { dispatch } = useContext(AuthContext);

  function handleCreateUser() {
    createUser(values)
      .then(data => {
        if (data.success) {
          setTimeout(() => {
            setIsButtonLoading(false);

            props.history.push({
              pathname: '/admin/users',
              state: {
                stateAlert: {
                  text: 'The user has been created.'
                }
              }
            });
          }, 1000 / 1);
        } else {
          dispatch({type: 'httpStatusCode', httpStatusCode: data.code});
        }
      });
  }

  return (
    <form onSubmit={handleSubmit} noValidate>
      <fieldset>
        <legend>New User</legend>
        <div className="form-group row">
          <label htmlFor="name" className="col-sm-3 col-form-label">Name</label>
          <div className="col-sm-6">
            <input type="text" id="name" name="name" value={values.name} onChange={handleChange} className={`form-control ${errors.name && 'is-invalid'}`} />
            {errors.name && (
              <div className="invalid-feedback">
                {errors.name}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="email" className="col-sm-3 col-form-label">Email</label>
          <div className="col-sm-6">
            <input type="input" id="email" name="email" value={values.email} onChange={handleChange} className={`form-control ${errors.email && 'is-invalid'}`} />
            {errors.email && (
              <div className="invalid-feedback">
                {errors.email}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="city" className="col-sm-3 col-form-label">Confirm Email</label>
          <div className="col-sm-6">
            <input type="input" id="confirmEmail" name="confirmEmail" value={values.confirmEmail} onChange={handleChange} className={`form-control ${errors.confirmEmail && 'is-invalid'}`} />
            {errors.confirmEmail && (
              <div className="invalid-feedback">
                {errors.confirmEmail}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="userType" className="col-sm-3 col-form-label">User Type</label>
          <div className="col-sm-6">
            <select id="userType" name="userType" value={values.userType} onChange={handleChange} className={`form-control ${errors.userType && 'is-invalid'}`}>
              <option value="">Please Select</option>
              <option value="admin">Codenology Administrator</option>
              <option value="company">Company User</option>
              <option value="standard">Standard User</option>
            </select>
            {errors.userType && (
              <div className="invalid-feedback">
                {errors.userType}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-6 offset-sm-3">
            <small id="createUserHelp" className="form-text text-muted">An activation link will be sent to the email address above.</small>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-6 offset-sm-3">
            <Button isButtonLoading={isButtonLoading} >Submit</Button>
          </div>
        </div>
      </fieldset>
    </form>
  );
}

export default withRouter(UserForm);
