import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { AuthConsumer } from '../contexts/AuthContext';

const PrivateUserRoute = ({component: Component, ...rest}) => (
  <AuthConsumer>
    {({ authState }) => (
      <Route
        {...rest}
        render={props =>
          authState.isLoggedIn ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location }
              }}
            />
          )
        }
      />
      )
    }
  </AuthConsumer>
)

export default PrivateUserRoute
