import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';

import Button from '../../../../../components/button';
import useForm from '../../../../../hooks/useForm';
import validate from '../shared/resourceFormValidation';
import { AuthContext } from '../../../../../contexts/AuthContext';
import { createResource } from '../../../../../services/resource';

function ResourceForm(props) {
  const moduleId = props.moduleId;
  const initialState = {
    name: '',
    description: '',
    url: '',
    method: '',
    moduleId: moduleId
  };
  const { values, handleChange, handleSubmit, isButtonLoading, setIsButtonLoading, errors } = useForm(initialState, handleCreateResource, validate);
  const { dispatch } = useContext(AuthContext);

  function handleCreateResource() {
    createResource(values)
      .then(data => {
        if (data.success) {
          setTimeout(() => {
            setIsButtonLoading(false);

            props.history.push({
              pathname: '/admin/modules/' + moduleId + '/resources',
              state: {
                stateAlert: {
                  text: 'The resource has been created.'
                }
              }
            });
          }, 1000 / 1);
        } else {
          dispatch({type: 'httpStatusCode', httpStatusCode: data.code});
        }
      });
  }

  return (
    <form onSubmit={handleSubmit} noValidate>
      <fieldset>
        <legend>New Resource</legend>
        <div className="form-group row">
          <label htmlFor="name" className="col-sm-3 col-form-label">Name</label>
          <div className="col-sm-6">
            <input type="text" id="name" name="name" value={values.name} onChange={handleChange} className={`form-control ${errors.name && 'is-invalid'}`} />
            {errors.name && (
              <div className="invalid-feedback">
                {errors.name}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="description" className="col-sm-3 col-form-label">Description</label>
          <div className="col-sm-6">
            <textarea id="description" name="description" value={values.description} onChange={handleChange} className={`form-control ${errors.description && 'is-invalid'}`} />
            {errors.description && (
              <div className="invalid-feedback">
                {errors.description}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="url" className="col-sm-3 col-form-label">Url</label>
          <div className="col-sm-6">
            <input type="input" id="url" name="url" value={values.url} onChange={handleChange} className={`form-control ${errors.url && 'is-invalid'}`} />
            {errors.url && (
              <div className="invalid-feedback">
                {errors.url}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="method" className="col-sm-3 col-form-label">Method</label>
          <div className="col-sm-6">
            <select id="method" name="method" value={values.method} onChange={handleChange} className="form-control">
              <option value="">Please Select</option>
              <option value="GET">GET</option>
              <option value="POST">POST</option>
              <option value="PUT">PUT</option>
              <option value="DELETE">DELETE</option>
            </select>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-6 offset-sm-3">
            <Button isButtonLoading={isButtonLoading} >Submit</Button>
          </div>
        </div>
      </fieldset>
    </form>
  );
}

export default withRouter(ResourceForm);
