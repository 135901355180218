import { useContext, useEffect, useState } from 'react';

import { AuthContext } from '../contexts/AuthContext';
import { getCompanyUsers } from '../services/companyUser';

const useCompanyUsers = (companyId) => {
  const [users, setUsers] = useState([]);
  const [isUsersLoading, setIsUsersLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const { dispatch } = useContext(AuthContext);

  useEffect(() => {
    getCompanyUsers(companyId)
      .then(data => {
        if (data.success) {
          setUsers(data.result);
          setIsUsersLoading(false);
          setRefresh(false);
        } else {
          dispatch({type: 'httpStatusCode', httpStatusCode: data.code});
        }
      });
  }, [companyId, refresh, dispatch]);

  return {
    users,
    isUsersLoading,
    setRefresh
  };
}

export default useCompanyUsers;
