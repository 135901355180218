import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';

import Button from '../../../../components/button';
import useForm from '../../../../hooks/useForm';
import validate from './profileFormValidation';
import { AuthContext } from '../../../../contexts/AuthContext';
import { updateProfile } from '../../../../services/user';

function ProfileForm(props) {
  const { user = {} } = props;
  const initialState = {
    name: user.name
  };

  const { values, handleChange, handleSubmit, isButtonLoading, setIsButtonLoading, errors } = useForm(initialState, handleUpdateProfile, validate);
  const { dispatch } = useContext(AuthContext);

  function handleUpdateProfile() {
    updateProfile(user.id, values)
      .then(data => {
        if (data.success) {
          setTimeout(() => {
            setIsButtonLoading(false);

            props.onAlert({
              text: 'Your profile information has been updated.'
            });

            dispatch({type: 'updateAuthState', authState: {
              name: values.name
            }});
          }, 1000 / 1);
        } else {
          dispatch({type: 'httpStatusCode', httpStatusCode: data.code});
        }
      });
  }

  return (
    <form onSubmit={handleSubmit} noValidate>
      <fieldset>
        <legend>Edit Profile</legend>
        <div className="form-group row">
          <label htmlFor="name" className="col-sm-3 col-form-label">Name</label>
          <div className="col-sm-6">
            <input type="text" id="name" name="name" value={values.name} onChange={handleChange} className={`form-control ${errors.name && 'is-invalid'}`} />
            {errors.name && (
              <div className="invalid-feedback">
                {errors.name}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-6 offset-sm-3">
            <Button isButtonLoading={isButtonLoading} >Submit</Button>
          </div>
        </div>
      </fieldset>
    </form>
  );
}

export default withRouter(ProfileForm);
