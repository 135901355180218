import React from 'react';
import { Route, Switch } from 'react-router-dom';

import CreateCompany from '../pages/admin/companies/create';
import CreateCompanyModule from '../pages/admin/companies/modules/create';
import CreateModule from '../pages/admin/modules/create';
import CreateResource from '../pages/admin/modules/resources/create';
import CreateUser from '../pages/admin/users/create';
import CreateUserCompany from '../pages/admin/users/companies/create';
import Companies from '../pages/admin/companies';
import CompanyModules from '../pages/admin/companies/modules';
import Dashboard from '../pages/admin/dashboard';
import EditModule from '../pages/admin/modules/edit';
import EditResource from '../pages/admin/modules/resources/edit';
import EditUser from '../pages/admin/users/edit';
import Modules from '../pages/admin/modules';
import NotFound from '../pages/not-found';
import PrivateAdminRoute from './PrivateAdminRoute';
import Resources from '../pages/admin/modules/resources';
import UserCompanies from '../pages/admin/users/companies';
import Users from '../pages/admin/users';

const AdminRoutes = () => (
  <Switch>
    <PrivateAdminRoute exact path='/admin' component={Dashboard} />
    <PrivateAdminRoute exact path='/admin/companies' component={Companies} />
    <PrivateAdminRoute path='/admin/companies/create' component={CreateCompany} />
    <PrivateAdminRoute exact path='/admin/companies/:companyId/modules' component={CompanyModules} />
    <PrivateAdminRoute path='/admin/companies/:companyId/modules/create' component={CreateCompanyModule} />
    <PrivateAdminRoute exact path='/admin/users' component={Users} />
    <PrivateAdminRoute path='/admin/users/create' component={CreateUser} />
    <PrivateAdminRoute path='/admin/users/:userId/edit' component={EditUser} />
    <PrivateAdminRoute exact path='/admin/users/:userId/companies' component={UserCompanies} />
    <PrivateAdminRoute path='/admin/users/:userId/companies/create' component={CreateUserCompany} />
    <PrivateAdminRoute exact path='/admin/modules' component={Modules} />
    <PrivateAdminRoute path='/admin/modules/create' component={CreateModule} />
    <PrivateAdminRoute path='/admin/modules/:moduleId/edit' component={EditModule} />
    <PrivateAdminRoute exact path='/admin/modules/:moduleId/resources' component={Resources} />
    <PrivateAdminRoute path='/admin/modules/:moduleId/resources/create' component={CreateResource} />
    <PrivateAdminRoute path='/admin/modules/:moduleId/resources/:resourceId/edit' component={EditResource} />
    <Route component={NotFound} />
  </Switch>
)

export default AdminRoutes
