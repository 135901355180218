import React from 'react';
import { Link } from 'react-router-dom';

import moment from 'moment';

import Alert from '../../../components/alert';
import HttpStatusCode from '../../../components/http-status-code';
import PageHeader from '../../../components/page-header';
import Sidebar from '../shared/sidebar';
import useAlert from '../../../hooks/useAlert';
import useCompanies from '../../../hooks/useCompanies';

function Companies(props) {
  const header = 'Administrator';
  const { location: { state = {} } } = props;
  const { stateAlert = {} } = state || {};
  const { companies } = useCompanies();
  const { alert } = useAlert(stateAlert);

  return (
    <React.Fragment>
      <HttpStatusCode prevMatch={props.match} />
      <div className="container">
        <div className="row">
          <div className="col-sm-2">
            <Sidebar />
          </div>
          <div className="col-sm-10">
            <PageHeader header={header} />
            <Alert alert={alert} />
            {companies.length > 0 ?
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Location</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Type</th>
                  <th scope="col">Date</th>
                  <th colSpan="2" className="icon text-right"><Link to="/admin/companies/create"><button type="button" className="btn btn-info btn-sm">Create</button></Link></th>
                </tr>
              </thead>
              <tbody>
                {companies.map((company, index) => (
                <tr key={index}>
                  <td><Link to={`/company/${company.id}`}>{company.name}</Link></td>
                  <td>{company.city}, {company.state}</td>
                  <td>{company.phone }</td>
                  <td>{company.company_type }</td>
                  <td>{moment(company.created_at).format('L')}</td>
                  <td className="text-right"><Link to={`/admin/companies/${company.id}/modules`}><i aria-label="Modules" className="fas fa-cubes" title="Modules" aria-hidden></i></Link></td>
                  <td className="text-right"><Link to={`/company/${company.id}`}><i aria-label="Dashboard" className="fas fa-tachometer-alt" title="Dashboard" aria-hidden></i></Link></td>
                </tr>
                ))}
              </tbody>
            </table> :
            <p>No companies found. Click <Link to="/admin/companies/create">here</Link> to create company.</p>
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Companies;
