import React from 'react';
import { Link } from 'react-router-dom';

import HttpStatusCode from '../../../../../components/http-status-code';
import NavTabs from '../../shared/nav-tabs';
import PageHeader from '../../../../../components/page-header';
import Sidebar from '../../../shared/sidebar';
import UserCompanyForm from './UserCompanyForm';
import useCompanies from '../../../../../hooks/useCompanies';
import useUser from '../../../../../hooks/useUser';

function CreateUserCompany(props) {
  const header = 'Administrator';
  const { match: { params } } = props;
  const userId = params.userId;
  const { user, isUserLoading } = useUser(userId);
  const { companies, isCompaniesLoading } = useCompanies();

  return (
    <React.Fragment>
      <HttpStatusCode prevMatch={props.match} />
      <div className="container">
        <div className="row">
          <div className="col-sm-2">
            <Sidebar />
          </div>
          <div className="col-sm-10">
            <PageHeader header={header} />
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/admin">Administrator</Link></li>
              <li className="breadcrumb-item"><Link to="/admin/users">Users</Link></li>
              <li className="breadcrumb-item"><Link to={`/admin/users/${user.id}/companies`}>Companies</Link></li>
              <li className="breadcrumb-item active">Create</li>
            </ol>
            <h3>{user.name}</h3>
            <NavTabs user={user} />
            <p>A business user can manage many companies.</p>
            {(!isUserLoading && !isCompaniesLoading) &&
            <UserCompanyForm userId={user.id} companies={companies} />
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CreateUserCompany;
