import React from 'react';
import { Link } from 'react-router-dom';

import Alert from '../../../../components/alert';
import HttpStatusCode from '../../../../components/http-status-code';
import PageHeader from '../../../../components/page-header';
import CensusForm from './CensusForm';
import Sidebar from '../../shared/sidebar';
import useAlert from '../../../../hooks/useAlert';
import useCompany from '../../../../hooks/useCompany';
import useCensus from '../../../../hooks/useCensus';

function EditCensus(props) {
  const { match: { params } } = props;
  const companyId = params.companyId;
  const censusId = params.censusId;
  const { company, isCompanyLoading } = useCompany(companyId);
  const { census, isCensusLoading } = useCensus(companyId, censusId);
  const { alert, setAlert } = useAlert({});
  const header = company.name + ' - ' + company.city + ', ' + company.state;

  function handleAlert(alert) {
    setAlert(alert);
  }

  return (
    <React.Fragment>
      <HttpStatusCode redirectPathname={props.location.pathname} prevMatch={props.match} />
      <div className="container">
        <div className="row">
          <div className="col-sm-2">
            <Sidebar companyId={company.id} />
          </div>
          <div className="col-sm-10">
            <PageHeader header={header} />
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to={`/company/${company.id}`}>Company</Link></li>
              <li className="breadcrumb-item"><Link to={`/company/${company.id}/censuses`}>Censuses</Link></li>
              <li className="breadcrumb-item active">Edit</li>
            </ol>
            <h3>{census.name}</h3>
            <Alert alert={alert} />
            {(!isCompanyLoading && !isCensusLoading) &&
            <CensusForm companyId={company.id} census={census} onAlert={handleAlert} />
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default EditCensus;
