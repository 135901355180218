export default async function validate(values) {
  let errors = {};

  if (!values.name) {
    errors.name = 'Name is required';
  }

  if (!values.street) {
    errors.street = 'Street is required';
  }

  if (!values.city) {
    errors.city = 'City is required';
  }

  if (!values.state) {
    errors.state = 'State is required';
  }

  if (!values.zipCode) {
    errors.zipCode = 'ZIP Code is required';
  }

  if (!values.phone) {
    errors.phone = 'Phone is required';
  }

  if (values.website) {
    const urlRegex = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www\.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/;

    if (!urlRegex.test(values.website)) {
      errors.website = 'Website must be a valid uri';
    }
  }

  if (values.hasOwnProperty('companyType') && !values.companyType) {
    errors.companyType = 'Type is required';
  }

  return await errors;
};
