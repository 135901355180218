export default async function validate(values) {
  let errors = {};

  if (!values.name) {
    errors.name = 'Name is required';
  }

  if (!values.code) {
    errors.code = 'Code is required';
  }

  return await errors;
};
