import { checkEmail } from '../../../../services/auth';

// using async/await to handle asynchronous checkEmail to get data and prevent empty errors.email on return
export default async function validate(values) {
  let errors = {};

  if (!values.email) {
    errors.email = 'Email address is required';
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email address is invalid';
  } else {
    await checkEmail(values.email)
      .then(data => {
        if (!data.success) {
          errors.email = 'Email is already taken';
        }
      });
  }

  if (values.confirmEmail !== values.email) {
    errors.confirmEmail = 'Email does not match';
  }

  return errors;
};
