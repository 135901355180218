import React from 'react';

import PageHeader from '../../../components/page-header';

function Tos() {
  const header = 'Terms of Service';

  return (
    <div className="container">
      <PageHeader header={header} />
      <p>Codenology (&ldquo;C&rdquo;) is a product of Codenology, LLC (&ldquo;CL&rdquo;), a business entity owned and operated under California law. When using or accessing our website (&ldquo;Site&rdquo;), you are hereby agreeing to these terms and conclude a legally binding contract with CL. Do not access this Site if you are unable or unwilling to abide by the terms of these Terms of Service.</p>
      <ol>
          <li>
              <h3>DEFINITIONS</h3>
              <ol style={{ listStyleType: 'decimal' }}>
                  <li>
                      <h4>Parties</h4>
                      <p>&ldquo;You&rdquo; and &ldquo;your&rdquo; refer to you, a user of this Site. Anyone who accesses, browses, crawls, or in any way uses this site is a &ldquo;user.&rdquo; &ldquo;We&rdquo;, &ldquo;our&rdquo;, and &ldquo;us&rdquo; refer to CL.</p>
                  </li>
                  <li>
                      <h4>Content</h4>
                      <p>&ldquo;Content&rdquo; includes text, photos, audio, video, location data, images, and all other forms of data or communication. &ldquo;Your Content&rdquo; is Content that you transmit or submit, through or in connection with this Site, including documents, messages and/or attachments, and any information that is displayed in your account profile. All transmitted mailings, images, documents, letters and/or attachments that you submit through the Site, which are directed to available recipients of your selection (&ldquo;Correspondence&rdquo;), are part of Your Content.</p><p>&ldquo;Content&rdquo; means Content that we create and make available to you in connection with your use of this Site. &ldquo;Third Party Content&rdquo; means content that comes from third parties, which is made available in connection with this Site.</p><p>Finally, &ldquo;Site Content means all Content which is available in connection with the Site, including Your Content, CL Content and Third Party Content.</p>
                  </li>
              </ol>
          </li>
          <li>
              <h3>CHANGES TO THE TERMS OF SERVICE</h3>
              <p>We can modify the Terms of Service (the &ldquo;Terms&ldquo;) If and when any changes are made, we will notify you by placing the revised version on the Site, and there will be an indicator on the top of the site showing when the last revision was made. Please continue to look at the Terms because any revisions will be binding on you. Modifications will become effective upon posting of the new Terms. You agree and understand that you access to and use of our Site after any modifications to the Terms demonstrates your acceptance of the modification, and revised contract.</p>
          </li>
          <li>
              <h3>USING THE SITE</h3>
              <ol style={{ listStyleType: 'decimal' }}>
                  <li>
                      <h4>Eligibility</h4>
                      <p>When using or accessing our Site, you are required to be 18 years of age or older and to have the power and authority to enter into these Terms.</p>
                  </li>
                  <li>
                      <h4>Permission to Use the Site; Handling of Correspondence</h4>
                      <p>You are given permission to use this Site subject to these Terms herein described. Your use of and access to the Site is at Your own risk. You specifically understand that after you submit Correspondence on the Site, your Correspondence will be printed and accessed by CL employees and/or contractors, who will place it into an envelope and drop it off at a U.S. Post Office for delivery. Postcards will not be placed into envelopes. Correspondence will then be accessed and handled by local law enforcement for the facility housing the inmate, just as would any other regular mail directed to that recipient. As such, You understand and agree that Your Correspondence may expose You to criminal and/or civil liability.</p>
                  </li>
                  <li>
                      <h4>Site Availability</h4>
                      <p>This Site can be modified, interrupted, discontinued, updated, or suspended at any time without liability and/or notice.</p>
                  </li>
                  <li>
                      <h4>User Accounts</h4>
                      <p>You must setup an account and provide accurate information about yourself to use the features on this Site. You alone are responsible for maintaining the confidentiality of your account password, and are responsible for any and all activities that occur in connection with your account. You agree to and must notify CL immediately for unauthorized use of your account. We have the right to close your account at any time, and without reason. Unless you are a bond company, a surety or treatment facility that has independently contracted with CL, the account you create is for non-commercial, personal use only. In creating your account, you must provide accurate information about yourself, i.e., you cannot create an account for someone else, nor can you impersonate anyone else. You are not allowed to setup multiple accounts.</p>
                  </li>
              </ol>
          </li>
          <li>
              <h3>CONTENT</h3>
              <ol style={{ listStyleType: 'decimal' }}>
                  <li>
                      <h4>Responsibility for Your Content</h4>
                      <p>Only you, and no one else, are responsible for the Content you submit to access this site in addition to Content you send through the site. Once submitted, Correspondence cannot be withdrawn. You assume all risks associated with Your Content, including anyone's reliance on its accuracy, quality and/or reliability, or any disclosure made that make you personally identifiable. You agree that any and all content submitted to and sent through our servers is your own. Further, you may not imply or indicate in any manner whatsoever that CL sponsors or endorses your Content. You may very well expose yourself to civil and criminal liability, if, for instance, you send content that is criminal in nature, e.g., child pornography, you admit to a crime, and/or advocate the commission and/or concealment of a crime. Furthermore, any depictions or content related to child pornography will be immediately turned over to law enforcement authorities. Also, you expose yourself to civil liability if Your Content is false, intentionally misleading or defamatory, violates any third party right, including any copyright, trademark, trade secret, privacy right, moral right, right of publicity, and/or any other intellectual property or proprietary right, or contains any other material that is unlawful.</p>
                  </li>
                  <li>
                      <h4>Ownership</h4>
                      <p>As between you and CL, you own Your Content, and we own C Content - including but not limited to interactive features, graphics, design, compilation, computer code, products, software, visual features, any and all other elements and components of the Site, excluding Your Content, and Third Party Content. We own the copyrights, service marks, trade names, logos, trademarks, and all intellectual property and propriety rights throughout the world (Hereinafter referred to as &ldquo;IP Rights&rdquo;) associated with C Content and the Site, which are both protected by trade dress, patent, trademark laws, copyright, and any and all other intellectual property rights and proprietary rights. You are not authorized nor will you be allowed to reproduce, modify, create derivative works or adaptations, distribute, or publicly display and/or exploit in any way C Content, except as authorized by CL. With the exception of what is expressly provided herein, CL does not grant you express or implied rights to the Site and C Content which is ours.</p>
                  </li>
                  <li>
                      <h4>Other</h4>
                      <p>We have neither an obligation to keep or provide you with copies of Your Content, nor do we guarantee confidentiality associated with Your Content.</p>
                  </li>
              </ol>
          </li>
          <li>
              <h3>RESTRICTIONS</h3>
              <ol>
                  <li style={{ listStyleType: 'none' }}>
                      <h4>You agree that you will not, nor will you assist, enable and/or encourage others to use this Site in order to:</h4>
                      <ol style={{ listStyleType: 'decimal' }}>
                          <li><p>Violate Content guidelines, for instance, by writing and/or submitting imagery/attachments that are criminal in nature, e.g., sending child pornography, etc., or by advocating a crime or furthering and/or concealing the commission of a crime - nor will you write false or defamatory content;</p></li>
                          <li><p>Violate third party rights, including any breach of confidence, copyright, trademark, patent, trade secret, privacy right, moral right, right of publicity and/or intellectual property and proprietary rights; Stalk, harm, threaten and/or harass others, promote bigotry or discrimination;</p></li>
                          <li><p>Solicit personal information from others;</p></li>
                          <li><p>Violate any and all relevant laws</p></li>
                          <li><p>Violate the Terms;</p></li>
                          <li><p>Adapt, modify, reproduce, appropriate, create derivative works or adaptations of, translate, distribute, public display, trade, sell, or in any way exploit this Site or Site Content (not including Your Content), except as expressly authorized by CL;</p></li>
                          <li><p>Use a robot, spider, search/retrieval app, or other device, process or means to access, scrape, retrieve, or index any part of this Site or any Site Content;</p></li>
                          <li><p>Revere engineer any and all parts of this Site;</p></li>
                          <li><p>Modify or remove a trademark, copyright, or other proprietary rights notice that is on any section of the Site, or on any materials copied or printed from the Site;</p></li>
                          <li><p>Mine, record or process information regarding other users;</p></li>
                          <li><p>Frame and/or reformat any part of this Site;</p></li>
                          <li><p>Retrieve, index, access any segment of this Site to populate and/or construct a searchable database of users;</p></li>
                          <li><p>Try to get unauthorized access to the Site, computer systems, user accounts, and/or networks associated with the Site by password mining, hacking, or any other means;</p></li>
                          <li><p>Disable, damage, remove, circumvent, and/or otherwise interfere and disrupt security features of the Site, which are features associated with preventing and/or restricting the use of copying Site Content or features which enforce limitations on Site use; or</p></li>
                          <li><p>Use the Site to violate the security of any computer network, unlock/crack passwords or encryption codes, disrupt and/or interfere with the security of, or otherwise cause harm to this Site, and Site Content.</p></li>
                      </ol>
                  </li>
                  <li style={{ listStyleType: 'none' }}>
                      <p>Each of the above restrictions apply only to the extent permission under applicable law.</p>
                  </li>
              </ol>
          </li>
          <li>
              <h3>GUIDELINES AND POLICIES</h3>
              <p>You agree and represent that you have read and understood our Privacy Policy. The Privacy Policy is to be read in conjunction with the Terms. Please be advised that we may disclose information pertaining to you to third parties if there is a good faith belief that such disclosure is reasonably necessary to (i) take action regarding suspected illegal activities/behavior; (ii) enforce and/or apply our Terms and Privacy Policy; (iii) comply with legal process or government inquiry, such as a search warrant, statute, subpoena, judicial action and/or proceeding, or to comply with other legal service served on CL; or (iv) protect our rights, reputation, and property, or that of our users, affiliates and the public. In addition to accessing, printing and packaging Correspondence by CL employees and/or contractors, Correspondence will be reviewed and handled by applicable law enforcement personnel as described in Section 3 of these Terms.</p>
          </li>
          <li>
              <h3>THIRD PARTIES</h3>
              <p>This Site may &ndash; from time to time &ndash; include links to other web addresses/pages or applications (&ldquo;Third Party Site&rdquo;). We have no control over, nor do we endorse or sponsor any Third Party Site. You agree that CL is not responsible for the availability of or content of Third Party Sites. Using a Third Party Site is at your own risk.</p>
          </li>
          <li>
              <h3>INDEMNITY</h3>
              <p>By using and/or accessing this Site, you to indemnify, defend, and hold CL, its parents, subsidiaries, affiliates, any related companies, suppliers, licensors and partners and the officers, directors, employees, members, managers, agents and representatives of each of them harmless, including costs, liabilities and legal fees, from any claim or demand made by any third party arising out of or relating to (i) your access to our use of this Site, (ii) your violation of the Terms, (iii) any products or services purchased or obtained by you in connection with the Site, or (iv) the infringement by you, or any third party using your account, or an intellectual property or other right of any person or entity. CL reserves the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us and you agree to cooperate with our defense of these claims. You agree not to settle any matter without prior written consent of CL will use reasonable efforts to notify you of any such claim, action or proceeding upon becoming aware of it.</p>
          </li>
          <li>
              <h3>DISCLAIMERS AND LIMITATIONS OF LIABILITY</h3>
              <p>Please read this section carefully, since it limits the liability of CWT and its subsidiaries, affiliates, and licensors (collectively, the &ldquo;CL Entities&rdquo;). Each of the subsections below only applies to the maximum extent allowed under applicable law. Nothing stated herein is intended to limit any rights you have which may not be lawfully limited.</p>
              <ol style={{ listStyleType: 'decimal' }}>
                  <li>
                      <p>THIS SITE IS AVAILABLE TO YOU ON AN &ldquo;AS IS&rdquo; BASIS, &ldquo;WITH ALL FAULTS&rdquo;, AND ON AN &ldquo;AS AVAILABLE&rdquo; BASIS. YOUR USE OF THE SITE IS AT YOUR OWN RISK. THE CL ENTITIES MAKE NO CLAIM OR PROMISES ABOUT THE QUALITY, ACCURACY, OR RELIABILITY OF THE SITE, ITS SAFETY OR SECURITY, OR THE SITE CONTENT. ACCORDINGLY, THE CL ENTITIES ARE NOT LIABLE TO YOU FOR ANY LOSS OR DAMAGE THAT MIGHT ARISE.</p>
                  </li>
                  <li>
                      <p>THE CL ENTITIES MAKE NO CLAIMS OR PROMISES WITH RESPECT TO ANY THIRD PARTY, SUCH AS THE BUSINESSES LISTED ON THE SITE. ACCORDINGLY, THE CL ENTITIES ARE NOT LIABLE TO YOU FOR LOSS OR DAMAGE THAT MIGHT ARISE FROM THEIR ACTIONS, INCLUDING, FOR EXAMPLE, IF ANOTHER USER MISUSES YOUR CONTENT OR IDENTITY. YOUR PURCHASE AND USE OF PRODUCTS OR SERVICES OFFERED BY THIRD PARTIES THROUGH THE SITE IS AT YOUR OWN DISCRETION AND RISK.</p>
                  </li>
                  <li>
                      <p>THE CL ENTITIES EXPRESSLY DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING WARRANTIES AS TO PRODUCTS OR SERVICES OFFERED BY BUSINESSES LISTED ON THE SITE, AND IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. NO ORAL OR WRITTEN INFORMATION OR ADVICE PROVIDED TO YOU BY A REPRESENTATIVE OF ONE OF THE CL ENTITIES SHALL CREATE A REPRESENTATION OR WARRANTY.</p>
                  </li>
                  <li>
                      <p>YOUR SOLE AND EXCLUSIVE RIGHT AND REMEDY IN CASE OF DISSATISFACTION WITH THE SITE, RELATED SERVICES, OR ANY OTHER GRIEVANCE SHALL BE YOUR TERMINATION AN DISCONTINUATION OF ACCESS TO, OR USE OF THE SITE. THE CL ENTITIES' MAXIMUM AGGREGATE LIABILITY TO YOU FOR LOSSES OR DAMAGES THAT YOU SUFFER IN CONNECTION WITH THE SITE OR THESE TERMS IS LIMITED TO THE GREATER OF (i) THE AMOUNT PAID, IF ANY, BY YOU TO THE CL ENTITIES IN CONNECTION WITH THE SITE IN THE 12 MONTHS PRIOR TO THE ACTION GIVING RISE TO LIABILITY, OR (ii) $100.</p>
                  </li>
                  <li>
                      <p>THE CL ENTITIES DISCLAIM ANY AND ALL LIABILITY FOR (i) INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY, RELIANCE, OR CONSEQUENTIAL DAMAGES, (ii) LOSS OF PROFITS, (iii) BUSINESS INTERRUPTION, (iv) REPUTATIONAL HARM, OR (v) LOSS OF INFORMATION OR DATA.</p>
                  </li>
              </ol>
          </li>
          <li>
              <h3>CHOICE OF LAW AND VENUE</h3>
              <p>California law will govern these terms, as well as any claim, cause of action or dispute that may arise between you and CL and/or the CL Entities (Hereinafter referred to as a &ldquo;Claim&rdquo;), without regard to conflict of law provisions. FOR ANY CLAIM BROUGHT BY EITHER PARTY, YOU AGREE TO SUBMIT AND CONSENT TO THE PERSONAL AND EXCLUSIVE JURISDICTION IN, AND THE EXCLUSIVE VENUE OF, THE STATE AND FEDERAL COURTS LOCATED WITHIN LOS ANGELES COUNTY, CALIFORNIA.</p>
          </li>
          <li>
              <h3>TERMINATION</h3>
              <ol style={{ listStyleType: 'decimal' }}>
                  <li>
                      <p>You may terminate the Terms at any time by closing your account and discontinuing your use of C.</p>
                  </li>
                  <li>
                      <p>We may close your account, suspend your ability to use portions of the Site, and/or prevent you altogether from the Site, for any or no reason, without notice or liability of any kind. Any such action could prevent you from accessing your account, the Site, Your Content, Site Content, or any other related information.</p>
                  </li>
                  <li>
                      <p>In the event of account termination, whether by you or us, relevant Terms above will continue in full force and effect.</p>
                  </li>
              </ol>
          </li>
          <li>
              <h3>GENERAL TERMS</h3>
              <ol style={{ listStyleType: 'decimal' }}>
                  <li>
                      <p>We have and reserve the right to update, modify, or discontinue the Site at our sole discretion, at any time, for any or no reason, and without notice or liability.</p>
                  </li>
                  <li>
                      <p>We may provide you with notices, including notices regarding changes to the Terms by email, regular mail or communications through the Site.</p>
                  </li>
                  <li>
                      <p>Except as stated in the sections above, nothing herein is intended, nor will be deemed, to confer rights or remedies upon any third party.</p>
                  </li>
                  <li>
                      <p>The terms contain the entire agreement between you and us regarding use of the Site, and supersede and any all other prior agreements between you and us regarding this subject matter. The parties acknowledge that no reliance is placed on any representation made but not expressly contained in the Terms.</p>
                  </li>
                  <li>
                      <p>If there is any failure on CL and/or the CL Entities&rsquo; part to exercise or enforce any rights or provisions of the Terms does not constitute a waiver of said rights or provisions. The failure of a party to exercise any right provided for herein shall not be deed a waiver of any rights herein stated.</p>
                  </li>
                  <li>
                      <p>Should any provision of the Terms later be found to be invalid or unenforceable, said provision shall be limited and/or eliminated to the minimum extent necessary such that the Terms shall otherwise stay in full force and effect, and remain enforceable.</p>
                  </li>
                  <li>
                      <p>The Terms are NOT transferable, assignable, or sub-licensable by you except with CL&rsquo;s or the CL Entities&rsquo; prior written consent, although the Terms may be assigned or transfer by us without restriction, and at any time. All assignments made in violation of the Terms is void. Each of the Section titles in the Terms are merely for convenience and have no legal and/or contractual effect.</p>
                  </li>
              </ol>
          </li>
          <li>
              <h3>INQUIRIES</h3>
              <p>If you believe that we have not adhered to the Terms of Service, you may write to us at the following address:</p>
              <address>
                  support{/* >@. */}@{/* >@. */}codenology{/* >@. */}.{/* >@. */}com
              </address>
          </li>
      </ol>
      <p>Effective Date: December 14, 2019</p>
    </div>
  );
}

export default Tos;
