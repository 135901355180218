import { getHeaders, logError, readResponseAsJSON, validateResponse } from '../utils/fetch';

export function createCompanyRoleUser(companyId, body) {
  const headers = getHeaders();
  const options = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: headers
  };

  return fetch('/api/companies/' + companyId + '/roles/' + body.roleId + '/users', options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}

export function getCompanyRoleUsers(companyId, roleId) {
  const headers = getHeaders();
  const options = {
    method: 'GET',
    headers: headers
  };

  return fetch('/api/companies/' + companyId + '/roles/' + roleId + '/users', options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}

export function deleteCompanyRoleUser(companyId, roleId, companyUserId) {
  const headers = getHeaders();
  const options = {
    method: 'DELETE',
    headers: headers
  };

  return fetch('/api/companies/' + companyId + '/roles/' + roleId + '/users/' + companyUserId, options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}
