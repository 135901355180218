import React from 'react';

import PageHeader from '../../components/page-header';

function Contact() {
  const header = 'Contact';

  return (
    <div className="container">
      <PageHeader header={header} />
      <p>We'd love to hear from you. Please email us at support{/* >@. */}@{/* >@. */}codenology{/* >@. */}.{/* >@. */}com with questions or feedback.</p>
    </div>
  );
}

export default Contact;
