import { useContext, useEffect, useState } from 'react';

import { AuthContext } from '../contexts/AuthContext';
import { getCompanyRoleUsers } from '../services/companyRoleUser';

const useCompanyRoleUsers = (companyId, roleId) => {
  const [users, setUsers] = useState([]);
  const [isUsersLoading, setIsUsersLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const { dispatch } = useContext(AuthContext);

  useEffect(() => {
    getCompanyRoleUsers(companyId, roleId)
      .then(data => {
        if (data.success) {
          setUsers(data.result);
          setIsUsersLoading(false);
          setRefresh(false);
        } else {
          dispatch({type: 'httpStatusCode', httpStatusCode: data.code});
        }
      });
  }, [companyId, roleId, refresh, dispatch]);

  return {
    users,
    isUsersLoading,
    setRefresh
  };
}

export default useCompanyRoleUsers;
