import { useContext, useEffect, useState } from 'react';

import { AuthContext } from '../contexts/AuthContext';
import { getCompanyUser } from '../services/companyUser';

const useCompanyUser = (companyId, userId) => {
  const [user, setUser] = useState({});
  const [isUserLoading, setIsUserLoading] = useState(true);
  const { dispatch } = useContext(AuthContext);

  useEffect(() => {
    getCompanyUser(companyId, userId)
      .then(data => {
        if (data.success) {
          setUser(data.result);
          setIsUserLoading(false);
        } else {
          dispatch({type: 'httpStatusCode', httpStatusCode: data.code});
        }
      });
  }, [companyId, userId, dispatch]);

  return {
    user,
    isUserLoading
  };
}

export default useCompanyUser;
