import React, { useEffect, useRef, useState } from 'react';

import { useSpring, animated } from 'react-spring';

import Loader from '../loader';

function Button({ isButtonLoading, children, ...props }) {
  // showLoader is used to stay in the "isButtonLoading state" a bit longer to avoid loading flashes if the loading state is too short.
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (isButtonLoading) {
      setShowLoader(true);
    }

    // Show loader a bits longer to avoid loading flash
    if (!isButtonLoading && showLoader) {
      const timeout = setTimeout(() => {
        setShowLoader(false);
      }, 400);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isButtonLoading, showLoader]);

  // Capture the dimensions of the button before the loading happens so it doesn’t change size. These hooks can be put in a seprate file.
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current && ref.current.getBoundingClientRect().width) {
      setWidth(ref.current.getBoundingClientRect().width);
    }
    if (ref.current && ref.current.getBoundingClientRect().height) {
      setHeight(ref.current.getBoundingClientRect().height);
    }
  }, [children]);

  // Hooks used to fade in/out the loader or the button contents
  const fadeOutProps = useSpring({ opacity: showLoader ? 1 : 0 });
  const fadeInProps = useSpring({ opacity: showLoader ? 0 : 1 });

  return (
    <button
      {...props}
      className="btn btn-primary"
      ref={ref}
      style={
        showLoader
          ? {
              width: `${width}px`,
              height: `${height}px`
            }
          : {}
      }
      disabled={showLoader ? true : false}
    >
      {showLoader ? (
        <animated.div style={fadeOutProps}>
          <Loader />
        </animated.div>
      ) : (
        <animated.div style={fadeInProps}>{children}</animated.div>
      )}
    </button>
  );
}

export default Button;
