import React, { useContext } from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';

import { AuthContext } from '../../contexts/AuthContext';

function Navbar(props) {
  const { authState, dispatch } = useContext(AuthContext);

  function handleLogout() {
    dispatch({type: 'logout'});
    props.history.push('/login');
  }

  return (
    <div className="navbar navbar-expand-lg fixed-top navbar-dark bg-primary">
      <div className="container">
        <Link to="/" className="navbar-brand">Codenology</Link>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink className="nav-link" to="/sites">Sites</NavLink>
            </li>
          </ul>
          {!authState.isLoggedIn && (
          <ul className="nav navbar-nav ml-auto">
            <li className="nav-item">
              <NavLink className="nav-link" to="/register">Register</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/login">Login</NavLink>
            </li>
          </ul>
          )}
          {authState.isLoggedIn && (
          <ul className="nav navbar-nav ml-auto">
            <li className="nav-item dropdown">
              <Link className="nav-link dropdown-toggle" data-toggle="dropdown" to="#" id="cogs">{ authState.name } <span className="caret"></span></Link>
                <div className="dropdown-menu" aria-labelledby="cogs">
                  <Link className="dropdown-item" to="/user"><i className="fas fa-home fa-fw" aria-hidden></i> Home</Link>
                  <Link className="dropdown-item" to="/user/settings/profile"><i className="fas fa-cog fa-fw" aria-hidden></i> Settings</Link>
                  {authState.userType === 'admin' && (
                  <Link className="dropdown-item" to="/admin"><i className="fas fa-fighter-jet fa-fw" aria-hidden></i> Administrator</Link>
                  )}
                  <button type="button" onClick={handleLogout} className="dropdown-item"><i className="fas fa-sign-out-alt fa-fw" aria-hidden></i> Logout</button>
                </div>
            </li>
          </ul>
          )}
        </div>
      </div>
    </div>
  );
}

export default withRouter(Navbar);
