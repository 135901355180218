import { useContext, useEffect, useState } from 'react';

import { AuthContext } from '../contexts/AuthContext';
import { getModule } from '../services/module';

const useModule = (moduleId) => {
  const [module, setModule] = useState({});
  const [isModuleLoading, setIsModuleLoading] = useState(true);
  const { dispatch } = useContext(AuthContext);

  useEffect(() => {
    getModule(moduleId)
      .then(data => {
        if (data.success) {
          setModule(data.result);
          setIsModuleLoading(false);
        } else {
          dispatch({type: 'httpStatusCode', httpStatusCode: data.code});
        }
      });
  }, [moduleId, dispatch]);

  return {
    module,
    isModuleLoading
  };
}

export default useModule;
