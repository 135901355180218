import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';

import Button from '../../../../components/button';
import useForm from '../../../../hooks/useForm';
import validate from './emailFormValidation';
import { AuthContext } from '../../../../contexts/AuthContext';
import { updateEmail } from '../../../../services/user';

function EmailForm(props) {
  const { user = {} } = props;
  const initialState = {
    currentEmail: user.email,
    email: '',
    confirmEmail: ''
  };

  const { values, handleChange, handleSubmit, isButtonLoading, setIsButtonLoading, errors, resetForm } = useForm(initialState, handleUpdateEmail, validate);
  const { dispatch } = useContext(AuthContext);

  function handleUpdateEmail() {
    updateEmail(user.id, values)
      .then(data => {
        if (data.success) {
          setTimeout(() => {
            setIsButtonLoading(false);

            props.onAlert({
              text: 'Your email has been updated.'
            });

            // update currentEmail and reset form
            initialState.currentEmail = values.email;
            resetForm(initialState);
          }, 1000 / 1);
        } else {
          setTimeout(() => {
            setIsButtonLoading(false);

            props.onAlert({
              text: 'The server was not able to process the request because of a conflict.',
              type: 'danger'
            });
          }, 1000 / 1);

          dispatch({type: 'httpStatusCode', httpStatusCode: data.code});
        }
      });
  }

  return (
    <form onSubmit={handleSubmit} noValidate>
      <fieldset>
        <legend>Edit Profile</legend>
        <div className="form-group row">
          <label htmlFor="name" className="col-sm-3 col-form-label">Current Email</label>
          <div className="col-sm-6">
            <input type="text" id="currentEmail" name="currentEmail" value={values.currentEmail} onChange={handleChange} className="form-control" readOnly />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="email" className="col-sm-3 col-form-label">New Email</label>
          <div className="col-sm-6">
            <input type="input" id="email" name="email" value={values.email} onChange={handleChange} className={`form-control ${errors.email && 'is-invalid'}`} />
            {errors.email && (
              <div className="invalid-feedback">
                {errors.email}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="city" className="col-sm-3 col-form-label">Confirm Email</label>
          <div className="col-sm-6">
            <input type="input" id="confirmEmail" name="confirmEmail" value={values.confirmEmail} onChange={handleChange} className={`form-control ${errors.confirmEmail && 'is-invalid'}`} />
            {errors.confirmEmail && (
              <div className="invalid-feedback">
                {errors.confirmEmail}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-6 offset-sm-3">
            <Button isButtonLoading={isButtonLoading} >Submit</Button>
          </div>
        </div>
      </fieldset>
    </form>
  );
}

export default withRouter(EmailForm);
