import { useContext, useEffect, useState } from 'react';

import { AuthContext } from '../contexts/AuthContext';
import { getCompanies } from '../services/company';

const useCompanies = () => {
  const [companies, setCompanies] = useState([]);
  const [isCompaniesLoading, setIsCompaniesLoading] = useState(true);
  const { dispatch } = useContext(AuthContext);

  useEffect(() => {
    getCompanies()
      .then(data => {
        if (data.success) {
          setCompanies(data.result);
          setIsCompaniesLoading(false);
        } else {
          dispatch({type: 'httpStatusCode', httpStatusCode: data.code});
        }
      });
  }, [dispatch]);

  return {
    companies,
    isCompaniesLoading
  };
}

export default useCompanies;
