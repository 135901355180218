export default async function validate(values) {
  let errors = {};

  if (!values.name) {
    errors.name = 'Name is required';
  }
  
  if (!values.description) {
    errors.description = 'Description is required';
  }

  if (!values.url) {
    errors.url = 'Url is required';
  }

  return await errors;
};
