import React from 'react';
import { NavLink } from 'react-router-dom';

function NavTabs(props) {
  const companyId = props.companyId;
  const roleId = props.roleId;

  return (
    <ul className="nav nav-tabs mb-4">
      <li className="nav-item">
        <NavLink className="nav-link" to={`/company/${companyId}/roles/${roleId}/edit`}>Edit</NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to={`/company/${companyId}/roles/${roleId}/users`}>Users</NavLink>
      </li>
    </ul>
  );
}

export default NavTabs;
