import React from 'react';
import { Link } from 'react-router-dom';

import CompanyRoleUserForm from './CompanyRoleUserForm';
import HttpStatusCode from '../../../../../components/http-status-code';
import NavTabs from '../../shared/nav-tabs';
import PageHeader from '../../../../../components/page-header';
import Sidebar from '../../../shared/sidebar';
import useCompany from '../../../../../hooks/useCompany';
import useCompanyUsers from '../../../../../hooks/useCompanyUsers';
import useRole from '../../../../../hooks/useRole';

function CreateCompanyRoleUser(props) {
  const { match: { params } } = props;
  const companyId = params.companyId;
  const roleId = params.roleId;
  const { company, isCompanyLoading } = useCompany(companyId);
  const { role, isRoleLoading } = useRole(companyId, roleId);
  const { users, isUsersLoading } = useCompanyUsers(companyId);
  const header = company.name + ' - ' + company.city + ', ' + company.state;

  return (
    <React.Fragment>
      <HttpStatusCode prevMatch={props.match} />
      <div className="container">
        <div className="row">
          <div className="col-sm-2">
            <Sidebar companyId={company.id} />
          </div>
          <div className="col-sm-10">
            <PageHeader header={header} />
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to={`/company/${company.id}`}>Company</Link></li>
              <li className="breadcrumb-item"><Link to={`/company/${company.id}/roles`}>Roles</Link></li>
              <li className="breadcrumb-item active">Edit</li>
            </ol>
            <h3>{role.name}</h3>
            <NavTabs companyId={company.id} roleId={role.id} />
            <p>Assign users to this role.</p>
            {(!isCompanyLoading && !isRoleLoading && !isUsersLoading) &&
            <CompanyRoleUserForm companyId={company.id} roleId={role.id} users={users} />
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CreateCompanyRoleUser;
