import { useContext, useEffect, useState } from 'react';

import { AuthContext } from '../contexts/AuthContext';
import { getCompanyModuleResources } from '../services/companyModule';

const useCompanyModuleResources = (companyId) => {
  const [resources, setResources] = useState([]);
  const [isResourcesLoading, setIsResourcesLoading] = useState(true);
  const { dispatch } = useContext(AuthContext);

  useEffect(() => {
    getCompanyModuleResources(companyId)
      .then(data => {
        if (data.success) {
          setResources(data.result);
          setIsResourcesLoading(false);
        } else {
          dispatch({type: 'httpStatusCode', httpStatusCode: data.code});
        }
      });
  }, [companyId, dispatch]);

  return {
    resources,
    isResourcesLoading
  };
}

export default useCompanyModuleResources;
