import React from 'react';

import PageHeader from '../../../components/page-header';
import Sidebar from '../shared/sidebar';

function Dashboard() {
  const header = 'Administrator';

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-2">
          <Sidebar />
        </div>
        <div className="col-sm-10">
          <PageHeader header={header} />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
