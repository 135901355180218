import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { AuthConsumer } from '../contexts/AuthContext';
import { CompanyProvider, CompanyConsumer } from '../contexts/CompanyContext';
import { hasCompanyModule } from '../utils/helper';

const PrivateCompanyRoute = ({component: Component, moduleCode, resourceName, ...rest}) => {
  return (
    <CompanyProvider companyId={rest.computedMatch.params.companyId}>
      <CompanyConsumer>
        {({ companyModules, isCompanyModulesLoading }) => {
          return (
            <AuthConsumer>
              {({ authState }) => {
                return (
                  <Route
                    {...rest}
                    render={props => {
                      const { match: { params } } = props;
                      const companyId = params.companyId;

                      if (authState.isLoggedIn) {
                        // company modules checked
                        if (!isCompanyModulesLoading && !hasCompanyModule(companyModules, moduleCode)) {
                            return (
                              <Redirect
                                to={{
                                  pathname: '/forbidden',
                                    state: {
                                      prevMatch: props.match
                                    }
                                }}
                              />
                            );
                        }

                        // user permission checked
                        if (authState.userType === 'admin' || (authState.userType === 'company' && authState.companies.some(company => parseInt(company.id) === parseInt(companyId)) && (authState.companies.filter(company => parseInt(company.id) === parseInt(companyId)).map(company => company.isAdmin).shift() || authState.companies.filter(company => parseInt(company.id) === parseInt(companyId)).map(company => company.roles.map(role => role.resources.some(resource => resource.name === resourceName)).shift()).shift()))) {
                          return (
                            <Component {...props} />
                          );
                        } else {
                          if (!isCompanyModulesLoading) {
                            if (authState.companies.some(company => parseInt(company.id) === parseInt(companyId))) {    // show forbidden page with a message since user is part of this company
                              return (
                                <Redirect
                                  to={{
                                    pathname: '/forbidden',
                                      state: {
                                        prevMatch: props.match,
                                        isCompanyUser: true
                                      }
                                  }}
                                />
                              );
                            } else {    // redirect to user dashboard since user is not part of this company
                              return (
                                <Redirect to='/user' />
                              );
                            }
                          }
                        }
                      } else {
                          return (
                            <Redirect to='/login' />
                          );
                      }
                    }
                  }
                  />
                )
              }
              }
            </AuthConsumer>
          )
        }
        }
      </CompanyConsumer>
    </CompanyProvider>
  )
}

export default PrivateCompanyRoute
