import React from 'react';
import { NavLink } from 'react-router-dom';

function NavTabs() {
  return (
    <ul className="nav nav-tabs mb-4">
      <li className="nav-item">
        <NavLink className="nav-link" to="/user/settings/profile">Profile</NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to="/user/settings/email">Email</NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link" to="/user/settings/password">Password</NavLink>
      </li>
    </ul>
  );
}

export default NavTabs;
