import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import moment from 'moment';

import Alert from '../../../components/alert';
import HttpStatusCode from '../../../components/http-status-code';
import PageHeader from '../../../components/page-header';
import Sidebar from '../shared/sidebar';
import useAlert from '../../../hooks/useAlert';
import useCompany from '../../../hooks/useCompany';
import useRoles from '../../../hooks/useRoles';
import { AuthContext } from '../../../contexts/AuthContext';
import { deleteRole } from '../../../services/role';

function Roles(props) {
  const { match: { params } } = props;
  const companyId = params.companyId;
  const { location: { state = {} } } = props;
  const { stateAlert = {} } = state || {};
  const { company } = useCompany(companyId);
  const { roles, setRefresh } = useRoles(companyId);
  const { alert, setAlert } = useAlert(stateAlert);
  const { dispatch } = useContext(AuthContext);
  const header = company.name + ' - ' + company.city + ', ' + company.state;

  function handleDeleteRole(roleId) {
    deleteRole(company.id, roleId)
      .then(data => {
        if (data.success) {
          setRefresh(true);
          setAlert({
            text: 'The role has been deleted.'
          });
        } else {
          dispatch({type: 'httpStatusCode', httpStatusCode: data.code});
        }
      });
  }

  return (
    <React.Fragment>
      <HttpStatusCode prevMatch={props.match} />
      <div className="container">
        <div className="row">
          <div className="col-sm-2">
            <Sidebar companyId={company.id} />
          </div>
          <div className="col-sm-10">
            <PageHeader header={header} />
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to={`/company/${company.id}`}>Company</Link></li>
              <li className="breadcrumb-item active">Roles</li>
            </ol>
            <Alert alert={alert} />
            <p>Roles determine what a user can and cannot do within a company.</p>
            {roles.length > 0 ?
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Description</th>
                  <th scope="col">Date</th>
                  <th className="icon text-right"><Link to={`/company/${company.id}/roles/create`}><button type="button" className="btn btn-info btn-sm">Create</button></Link></th>
                </tr>
              </thead>
              <tbody>
                {roles.map((role, index) => (
                <tr key={index}>
                  <td><Link to={`/company/${company.id}/roles/${role.id}/edit`}>{role.name}</Link></td>
                  <td>{role.description}</td>
                  <td>{moment(role.created_at).format('L')}</td>
                  <td className="text-right"><button type="button" className="btn btn-warning btn-sm" onClick={() => handleDeleteRole(role.id)}>Delete</button></td>
                </tr>
                ))}
              </tbody>
            </table> :
            <p>No roles found. Click <Link to={`/company/${company.id}/roles/create`}>here</Link> to create role.</p>
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Roles;
