import React, { createContext } from 'react';

import useCompanyModules from '../hooks/useCompanyModules';

const CompanyContext = createContext(null);

const CompanyProvider = ({ children, companyId }) => {
  const { companyModules, isCompanyModulesLoading } = useCompanyModules(companyId);

  return (
    <CompanyContext.Provider value={{ companyModules, isCompanyModulesLoading }}>
      { children }
    </CompanyContext.Provider>
  );
};

const CompanyConsumer = CompanyContext.Consumer;

export { CompanyContext, CompanyProvider, CompanyConsumer };
