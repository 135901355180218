import React from 'react';

function Home() {
  return (
    <div className="container">
    </div>
  );
}

export default Home;
