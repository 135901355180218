import { useState } from 'react';

const useAlert = (initialState) => {
  const [alert, setAlert] = useState(initialState);
 
  return {
    alert,
    setAlert
  };
}

export default useAlert;
