import { getHeaders, logError, readResponseAsJSON, validateResponse } from '../utils/fetch';

export function createUserCompany(body) {
  const headers = getHeaders();
  const options = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: headers
  };

  return fetch('/api/users/' + body.userId + '/companies', options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}

export function getUserCompanies(userId) {
  const headers = getHeaders();
  const options = {
    method: 'GET',
    headers: headers
  };

  return fetch('/api/users/' + userId + '/companies', options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}

export function deleteUserCompany(userId, companyId) {
  const headers = getHeaders();
  const options = {
    method: 'DELETE',
    headers: headers
  };

  return fetch('/api/users/' + userId + '/companies/' + companyId, options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}
