import { getHeaders, logError, readResponseAsJSON, validateResponse } from '../utils/fetch';

export function createRole(body) {
  const headers = getHeaders();
  const options = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: headers
  };

  return fetch('/api/companies/' + body.companyId + '/roles', options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}

export function getRoles(companyId) {
  const headers = getHeaders();
  const options = {
    method: 'GET',
    headers: headers
  };

  return fetch('/api/companies/' + companyId + '/roles', options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}

export function getRole(companyId, roleId) {
  const headers = getHeaders();
  const options = {
    method: 'GET',
    headers: headers
  };

  return fetch('/api/companies/' + companyId + '/roles/' + roleId, options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}

export function updateRole(companyId, roleId, body) {
  const headers = getHeaders();
  const options = {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: headers
  };

  return fetch('/api/companies/' + companyId + '/roles/' + roleId, options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}

export function deleteRole(companyId, roleId) {
  const headers = getHeaders();
  const options = {
    method: 'DELETE',
    headers: headers
  };

  return fetch('/api/companies/' + companyId + '/roles/' + roleId, options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}
