import { useContext, useEffect, useState } from 'react';

import { AuthContext } from '../contexts/AuthContext';
import { getCensuses } from '../services/census';

const useCensuses = (companyId) => {
  const [censuses, setCensuses] = useState([]);
  const [isCensusesLoading, setIsCensusesLoading] = useState(true);
  const { dispatch } = useContext(AuthContext);

  useEffect(() => {
    getCensuses(companyId)
      .then(data => {
        if (data.success) {
          setCensuses(data.result);
          setIsCensusesLoading(false);
        } else {
          dispatch({type: 'httpStatusCode', httpStatusCode: data.code});
        }
      });
  }, [companyId, dispatch]);

  return {
    censuses,
    isCensusesLoading
  };
}

export default useCensuses;
