import { getHeaders, logError, readResponseAsJSON, validateResponse } from '../utils/fetch';

export function createResource(body) {
  const headers = getHeaders();
  const options = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: headers
  };

  return fetch('/api/modules/' + body.moduleId + '/resources', options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}

export function getResources(moduleId) {
  const headers = getHeaders();
  const options = {
    method: 'GET',
    headers: headers
  };

  return fetch('/api/modules/' + moduleId + '/resources', options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}

export function getResource(moduleId, resourceId) {
  const headers = getHeaders();
  const options = {
    method: 'GET',
    headers: headers
  };

  return fetch('/api/modules/' + moduleId + '/resources/' + resourceId, options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}

export function updateResource(moduleId, resourceId, body) {
  const headers = getHeaders();
  const options = {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: headers
  };

  return fetch('/api/modules/' + moduleId + '/resources/' + resourceId , options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}

export function deleteResource(moduleId, resourceId) {
  const headers = getHeaders();
  const options = {
    method: 'DELETE',
    headers: headers
  };

  return fetch('/api/modules/' + moduleId + '/resources/' + resourceId, options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}
