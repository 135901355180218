import { useContext, useEffect, useState } from 'react';

import { AuthContext } from '../contexts/AuthContext';
import { getUserCompanies } from '../services/userCompany';

const useUserCompanies = (userId) => {
  const [companies, setCompanies] = useState([]);
  const [isCompaniesLoading, setIsCompaniesLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const { dispatch } = useContext(AuthContext);

  useEffect(() => {
    getUserCompanies(userId)
      .then(data => {
        if (data.success) {
          setCompanies(data.result);
          setIsCompaniesLoading(false);
          setRefresh(false);
        } else {
          dispatch({type: 'httpStatusCode', httpStatusCode: data.code});
        }
      });
  }, [userId, refresh, dispatch]);

  return {
    companies,
    isCompaniesLoading,
    setRefresh
  };
}

export default useUserCompanies;
