import { getHeaders, logError, readResponseAsJSON, validateResponse } from '../utils/fetch';

export function createCompany(body) {
  const headers = getHeaders();
  const options = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: headers
  };

  return fetch('/api/companies', options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}

export function getCompanies() {
  const headers = getHeaders();
  const options = {
    method: 'GET',
    headers: headers
  };

  return fetch('/api/companies', options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}

export function getCompany(companyId) {
  const headers = getHeaders();
  const options = {
    method: 'GET',
    headers: headers
  };

  return fetch('/api/companies/' + companyId, options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}

export function updateCompany(companyId, body) {
  const headers = getHeaders();
  const options = {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: headers
  };

  return fetch('/api/companies/' + companyId, options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}
