import React from 'react';
import { withRouter } from 'react-router-dom';

import Button from '../../../components/button';
import useForm from '../../../hooks/useForm';
import validate from './forgotPasswordFormValidation';
import { forgotPassword } from '../../../services/auth';

function ForgotPasswordForm(props) {
  const initialState = {
    email: ''
  };
  const { values, handleChange, handleSubmit, isButtonLoading, setIsButtonLoading, errors, resetForm } = useForm(initialState, handleForgotPassword, validate);

  function handleForgotPassword() {
    forgotPassword(values.email)
      .then(data => {
        setTimeout(() => {
          setIsButtonLoading(false);

          if (data.success) {
            props.onAlert({
              text: 'We have emailed your password reset link!'
            });

            resetForm();
          } else {
            props.onAlert({
              text: 'We can\'t find a user with that email address.',
              type: 'danger'
            });
          }
        }, 1000 / 1);
      });
  }

  return (
    <form onSubmit={handleSubmit} noValidate>
      <div className="form-group row">
        <div className="col-sm-6 offset-sm-3">
          <p>Enter the email address you used to register. We'll email you a link to reset your password.</p>
          <input type="email" id="email" name="email" value={values.email} onChange={handleChange} className={`form-control ${errors.email && 'is-invalid'}`} />
          {errors.email && (
            <div className="invalid-feedback">
              {errors.email}
            </div>
          )}
        </div>
      </div>
      <div className="form-group row">
        <div className="col-sm-6 offset-sm-3">
          <Button isButtonLoading={isButtonLoading} >Send</Button>
        </div>
      </div>
    </form>
  );
}

export default withRouter(ForgotPasswordForm);
