import React from 'react';

import PageHeader from '../../components/page-header';

function About() {
  const header = 'About';

  return (
    <div className="container">
      <PageHeader header={header} />
    </div>
  );
}

export default About;
