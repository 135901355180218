import React, { useContext } from 'react';
import { Link, withRouter } from 'react-router-dom';

import useForm from '../../hooks/useForm';
import { AuthContext } from '../../contexts/AuthContext';
import { login } from '../../services/auth';
import './Login.css';

function LoginForm(props) {
  const { state = {} } = props.location;
  const { from } = state || false;
  const initialState = {
    email: '',
    password: ''
  };
  const { values, handleChange, handleSubmit } = useForm(initialState, handleLogin);
  const { dispatch } = useContext(AuthContext);

  function handleLogin() {
    login(values.email, values.password)
      .then(data => {
        if (data.success) {
          dispatch({type: 'login'});
          props.history.push(from || '/user');
        } else {
          props.onAlert({
            text: 'Incorrect email or password.',
            type: 'danger'
          });
        }
      });
  }

  return (
    <form onSubmit={handleSubmit} className="form-login">
      <fieldset>
        <div className="form-group">
          <label htmlFor="email" className="control-label">Email</label>
          <input type="email" id="email" name="email" value={values.email} onChange={handleChange} className="form-control" />
        </div>
        <div className="form-group">
          <label htmlFor="password" className="control-label">Password</label>
          <input type="password" id="password" name="password" value={values.password} onChange={handleChange} className="form-control" />
        </div>
        <button className="btn btn-primary">Log In</button>
        <hr />
        <p><Link to="/password/forgot">Forgot your password?</Link></p>
      </fieldset>
    </form>
  );
}

export default withRouter(LoginForm);
