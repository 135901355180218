import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';

import Button from '../../../../components/button';
import useForm from '../../../../hooks/useForm';
import validate from '../shared/roleFormValidation';
import { AuthContext } from '../../../../contexts/AuthContext';
import { createRole } from '../../../../services/role';

function RoleForm(props) {
  const companyId = props.companyId;
  const { companyModules = {} } = props;
  const { resources = {} } = props;
  const initialState = {
    name: '',
    description: '',
    companyId: companyId,
    resourceIds: []
  };
  const { values, handleChange, handleSubmit, isButtonLoading, setIsButtonLoading, errors } = useForm(initialState, handleCreateRole, validate);
  const { dispatch } = useContext(AuthContext);

  function handleCreateRole() {
    createRole(values)
      .then(data => {
        if (data.success) {
          setTimeout(() => {
            setIsButtonLoading(false);

            props.history.push({
              pathname: '/company/' + companyId + '/roles',
              state: {
                stateAlert: {
                  text: 'The role has been created.'
                }
              }
            });
          }, 1000 / 1);
        } else {
          dispatch({type: 'httpStatusCode', httpStatusCode: data.code});
        }
      });
  }

  return (
    <form onSubmit={handleSubmit} noValidate>
      <fieldset>
        <legend>New Role</legend>
        <div className="form-group row">
          <label htmlFor="name" className="col-sm-3 col-form-label">Name</label>
          <div className="col-sm-6">
            <input type="text" id="name" name="name" value={values.name} onChange={handleChange} className={`form-control ${errors.name && 'is-invalid'}`} />
            {errors.name && (
              <div className="invalid-feedback">
                {errors.name}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="description" className="col-sm-3 col-form-label">Description</label>
          <div className="col-sm-6">
            <textarea id="description" name="description" value={values.description} onChange={handleChange} className="form-control" />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-3 text-right">Resources</div>
          <div className="col-sm-6">
            {companyModules.map((companyModule, indexCompanyModule) => (
            <div className="form-group" key={indexCompanyModule}>
              <p className="mb-1"><strong>{companyModule.name} Module</strong></p>
              {resources.map((resource, indexResource) => (
                companyModule.id === resource.module_id &&
              <div className="form-check mb-2" key={indexResource}>
                <input type="checkbox" id={`${resource.name}.${resource.resource_id}`} name="resourceIds" multiple={true} value={resource.resource_id} onChange={handleChange} className="form-check-input" />
                <label htmlFor={`${resource.name}.${resource.resource_id}`} className="form-check-label">
                  name: {resource.name}<br />
                  <span className="text-muted">description: {resource.description}</span><br />
                  <span className="text-muted">url: {resource.url}</span><br />
                  <span className="text-muted">method: {resource.method}</span>
                </label>
              </div>
              ))}
            </div>
            ))}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-6 offset-sm-3">
            <Button isButtonLoading={isButtonLoading} >Submit</Button>
          </div>
        </div>
      </fieldset>
    </form>
  );
}

export default withRouter(RoleForm);
