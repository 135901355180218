import React from 'react';
import { Link } from 'react-router-dom';

import moment from 'moment';

import Alert from '../../../components/alert';
import HttpStatusCode from '../../../components/http-status-code';
import PageHeader from '../../../components/page-header';
import Sidebar from '../shared/sidebar';
import useAlert from '../../../hooks/useAlert';
import useModules from '../../../hooks/useModules';

function Modules(props) {
  const header = 'Administrator';
  const { location: { state = {} } } = props;
  const { stateAlert = {} } = state || {};
  const { modules } = useModules();
  const { alert } = useAlert(stateAlert);

  return (
    <React.Fragment>
      <HttpStatusCode prevMatch={props.match} />
      <div className="container">
        <div className="row">
          <div className="col-sm-2">
            <Sidebar />
          </div>
          <div className="col-sm-10">
            <PageHeader header={header} />
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/admin">Administrator</Link></li>
              <li className="breadcrumb-item active">Modules</li>
            </ol>
            <Alert alert={alert} />
            {modules.length > 0 ?
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Code</th>
                  <th scope="col">Description</th>
                  <th scope="col">Date</th>
                  <th className="icon text-right"><Link to="/admin/modules/create"><button type="button" className="btn btn-info btn-sm">Create</button></Link></th>
                </tr>
              </thead>
              <tbody>
                {modules.map((module, index) => (
                <tr key={index}>
                  <td><Link to={`/admin/modules/${module.id}/edit`}>{module.name}</Link></td>
                  <td>{module.code}</td>
                  <td>{module.description}</td>
                  <td>{moment(module.created_at).format('L')}</td>
                  <td className="text-right"><Link to={`/admin/modules/${module.id}/edit`}><i aria-label="Modules" className="fas fa-edit" title="Edit" aria-hidden></i></Link></td>
                </tr>
                ))}
              </tbody>
            </table> :
            <p>No modules found. Click <Link to="/admin/modules/create">here</Link> to create module.</p>
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Modules;
