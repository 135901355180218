import { getHeaders, logError, readResponseAsBlob, readResponseAsJSON, validateResponse } from '../utils/fetch';

export function checkCensusName(companyId, name) {
  const body = {
    name: name,
    companyId: companyId
  };
  const headers = getHeaders();
  const options = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: headers
  };

  return fetch('/api/companies/' + companyId + '/censuses/check', options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}

export function createCensus(body) {
  const headers = getHeaders();
  const options = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: headers
  };

  return fetch('/api/companies/' + body.companyId + '/censuses', options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}

export function getCensuses(companyId) {
  const headers = getHeaders();
  const options = {
    method: 'GET',
    headers: headers
  };

  return fetch('/api/companies/' + companyId + '/censuses', options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}

export function getCensus(companyId, censusId) {
  const headers = getHeaders();
  const options = {
    method: 'GET',
    headers: headers
  };

  return fetch('/api/companies/' + companyId + '/censuses/' + censusId, options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}

export function updateCensus(companyId, censusId, body) {
  const headers = getHeaders();
  const options = {
    method: 'PUT',
    body: JSON.stringify(body),
    headers: headers
  };

  return fetch('/api/companies/' + companyId + '/censuses/' + censusId, options)
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(logError);
}

export function downloadCensusData(companyId, censusId) {
  const headers = getHeaders();
  const options = {
    method: 'GET',
    headers: headers
  };

  return fetch('/api/companies/' + companyId + '/censuses/' + censusId + '/download', options)
    .then(validateResponse)
    .then(readResponseAsBlob)
    .catch(logError);
}
