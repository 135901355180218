import React from 'react';

import PageHeader from '../../../components/page-header';
import Sidebar from '../shared/sidebar';
import useCompany from '../../../hooks/useCompany';

function Dashboard(props) {
  const { match: { params } } = props;
  const companyId = params.companyId;
  const { company } = useCompany(companyId);
  const header = company.name + ' - ' + company.city + ', ' + company.state;

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-2">
          <Sidebar companyId={companyId} />
        </div>
        <div className="col-sm-10">
          <PageHeader header={header} />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
