import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';

import Button from '../../../../../components/button';
import useForm from '../../../../../hooks/useForm';
import validate from '../shared/userCompanyFormValidation';
import { AuthContext } from '../../../../../contexts/AuthContext';
import { createUserCompany } from '../../../../../services/userCompany';

function UserCompanyForm(props) {
  const userId = props.userId;
  const companies = props.companies;
  const initialState = {
    userId: userId,
    companyId: '',
    isAdmin: false
  };
  const { values, handleChange, handleSubmit, isButtonLoading, setIsButtonLoading, errors } = useForm(initialState, handleCreateUserCompany, validate);
  const { dispatch } = useContext(AuthContext);

  function handleCreateUserCompany() {
    createUserCompany(values)
      .then(data => {
        if (data.success) {
          setTimeout(() => {
            setIsButtonLoading(false);

            props.history.push({
              pathname: '/admin/users/' + userId + '/companies',
              state: {
                stateAlert: {
                  text: 'The company has been assigned.'
                }
              }
            });
          }, 1000 / 1);
        } else {
          dispatch({type: 'httpStatusCode', httpStatusCode: data.code});
        }
      });
  }

  return (
    <form onSubmit={handleSubmit} noValidate>
      <fieldset>
        <legend>New User Company</legend>
        <div className="form-group row">
          <label htmlFor="companyId" className="col-sm-3 col-form-label">Company</label>
          <div className="col-sm-6">
            <select id="companyId" name="companyId" value={values.companyId} onChange={handleChange} className={`form-control ${errors.companyId && 'is-invalid'}`}>
              <option value="">Please Select</option>
              {companies.map((company, index) => (
              <option key={index} value={company.id}>{company.name} - {company.city}, {company.state}</option>
              ))}
            </select>
            {errors.companyId && (
              <div className="invalid-feedback">
                {errors.companyId}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-3 text-right">Administrator</div>
          <div className="col-sm-6">
            <div className="form-check">
              <input type="checkbox" id="isAdmin" name="isAdmin" onChange={handleChange} className="form-check-input" />
              <label htmlFor="isAdmin" className="form-check-label">Allow this user to be an Administrator for this company.</label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-6 offset-sm-3">
            <Button isButtonLoading={isButtonLoading} >Submit</Button>
          </div>
        </div>
      </fieldset>
    </form>
  );
}

export default withRouter(UserCompanyForm);
