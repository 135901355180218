import React from 'react';

import Alert from '../../components/alert';
import PageHeader from '../../components/page-header';
import RegisterForm from './RegisterForm';
import useAlert from '../../hooks/useAlert';

function Register() {
  const header = 'Join Codenology';
  const { alert, setAlert } = useAlert({});

  function handleAlert(alert) {
    setAlert(alert);
  }

  return (
    <div className="container">
      <PageHeader header={header} />
      <Alert alert={alert} />
      <RegisterForm onAlert={handleAlert} />
    </div>
  );
}

export default Register;
