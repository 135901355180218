import React from 'react';
import { Link } from 'react-router-dom';

import HttpStatusCode from '../../../../../components/http-status-code';
import NavTabs from '../../shared/nav-tabs';
import PageHeader from '../../../../../components/page-header';
import ResourceForm from './ResourceForm';
import Sidebar from '../../../shared/sidebar';
import useModule from '../../../../../hooks/useModule';

function CreateResource(props) {
  const header = 'Administrator';
  const { match: { params } } = props;
  const moduleId = params.moduleId;
  const { module, isModuleLoading } = useModule(moduleId);

  return (
    <React.Fragment>
      <HttpStatusCode prevMatch={props.match} />
      <div className="container">
        <div className="row">
          <div className="col-sm-2">
            <Sidebar />
          </div>
          <div className="col-sm-10">
            <PageHeader header={header} />
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/admin">Administrator</Link></li>
              <li className="breadcrumb-item"><Link to="/admin/modules">Modules</Link></li>
              <li className="breadcrumb-item"><Link to={`/admin/modules/${module.id}/resources`}>Resources</Link></li>
              <li className="breadcrumb-item active">Create</li>
            </ol>
            <h3>{module.name}</h3>
            <NavTabs moduleId={module.id} />
            <p>Resources are the pages and functions that make up a module. Module resources are used to set up role permissions.</p>
            {!isModuleLoading &&
            <ResourceForm moduleId={module.id} />
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CreateResource;
