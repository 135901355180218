import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';

import Button from '../../../components/button';
import useForm from '../../../hooks/useForm';
import validate from '../shared/companyFormValidation';
import { AuthContext } from '../../../contexts/AuthContext';
import { updateCompany } from '../../../services/company';

function CompanyForm(props) {
  const { company = {} } = props;
  const companyId = company.id;
  const initialState = {
    name: company.name,
    street: company.street,
    city: company.city,
    state: company.state,
    zipCode: company.zip_code,
    phone: company.phone,
    website: company.website,
    description: company.description,
    companyType: company.company_type
  };

  const { values, handleChange, handleSubmit, isButtonLoading, setIsButtonLoading, errors } = useForm(initialState, handleUpdateCompany, validate);
  const { dispatch } = useContext(AuthContext);

  function handleUpdateCompany() {
    updateCompany(companyId, values)
      .then(data => {
        if (data.success) {
          setTimeout(() => {
            setIsButtonLoading(false);

            props.onAlert({
              text: 'The company has been updated.'
            });
          }, 1000 / 1);
        } else {
          dispatch({type: 'httpStatusCode', httpStatusCode: data.code});
        }
      });
  }

  return (
    <form onSubmit={handleSubmit} noValidate>
      <fieldset>
        <legend>Edit Company</legend>
        <div className="form-group row">
          <label htmlFor="name" className="col-sm-3 col-form-label">Name</label>
          <div className="col-sm-6">
            <input type="text" id="name" name="name" value={values.name} onChange={handleChange} className={`form-control ${errors.name && 'is-invalid'}`} />
            {errors.name && (
              <div className="invalid-feedback">
                {errors.name}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="street" className="col-sm-3 col-form-label">Street</label>
          <div className="col-sm-6">
            <input type="input" id="street" name="street" value={values.street} onChange={handleChange} className={`form-control ${errors.street && 'is-invalid'}`} />
            {errors.street && (
              <div className="invalid-feedback">
                {errors.street}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="city" className="col-sm-3 col-form-label">City</label>
          <div className="col-sm-6">
            <input type="input" id="city" name="city" value={values.city} onChange={handleChange} className={`form-control ${errors.city && 'is-invalid'}`} />
            {errors.city && (
              <div className="invalid-feedback">
                {errors.city}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="state" className="col-sm-3 col-form-label">State</label>
          <div className="col-sm-6">
            <input type="input" id="state" name="state" value={values.state} onChange={handleChange} className={`form-control ${errors.state && 'is-invalid'}`} />
            {errors.state && (
              <div className="invalid-feedback">
                {errors.state}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="zipCode" className="col-sm-3 col-form-label">ZIP Code</label>
          <div className="col-sm-6">
            <input type="input" id="zipCode" name="zipCode" value={values.zipCode} onChange={handleChange} className={`form-control ${errors.zipCode && 'is-invalid'}`} />
            {errors.zipCode && (
              <div className="invalid-feedback">
                {errors.zipCode}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="phone" className="col-sm-3 col-form-label">Phone</label>
          <div className="col-sm-6">
            <input type="input" id="phone" name="phone" value={values.phone} onChange={handleChange} className={`form-control ${errors.phone && 'is-invalid'}`} />
            {errors.phone && (
              <div className="invalid-feedback">
                {errors.phone}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="website" className="col-sm-3 col-form-label">Website</label>
          <div className="col-sm-6">
            <input type="input" id="website" name="website" value={values.website} onChange={handleChange} className={`form-control ${errors.website && 'is-invalid'}`} />
            {errors.website && (
              <div className="invalid-feedback">
                {errors.website}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="description" className="col-sm-3 col-form-label">Description</label>
          <div className="col-sm-6">
            <textarea id="description" name="description" value={values.description} onChange={handleChange} className="form-control" />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="companyType" className="col-sm-3 col-form-label">Type</label>
          <div className="col-sm-6">
            <select id="companyType" name="companyType" value={values.companyType} onChange={handleChange} className={`form-control ${errors.companyType && 'is-invalid'}`}>
              <option value="">Please Select</option>
              <option value="business">Business</option>
              <option value="education">Education</option>
              <option value="government">Government</option>
              <option value="nonprofit">Nonprofit</option>
            </select>
            {errors.companyType && (
              <div className="invalid-feedback">
                {errors.companyType}
              </div>
            )}
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-6 offset-sm-3">
            <Button isButtonLoading={isButtonLoading} >Submit</Button>
          </div>
        </div>
      </fieldset>
    </form>
  );
}

export default withRouter(CompanyForm);
