import { useReducer } from 'react';

import { getTokenState, updateAuthState } from '../utils/token';
import { logout } from '../services/auth';

const useAuth = (initialState) => {
  const [authState, dispatch] = useReducer(reducer, initialState);

  function reducer(state, action) {
    switch (action.type) {
      case 'login':
        return handleLogin();
      case 'logout':
        return handleLogout();
      case 'updateAuthState':
        return handleUpdateAuthState(action.authState);
      case 'httpStatusCode':
        return handleHttpStatusCode(action.httpStatusCode);
      default:
        return state;
    }
  }

  function handleAuthState() {
    const authState = getTokenState();

    return authState;
  }
  
  function handleHttpStatusCode(httpStatusCode) {
    const authState = {...getTokenState(), httpStatusCode: httpStatusCode};

    return authState;
  }

  function handleLogin() {
    return handleAuthState();
  }

  function handleLogout() {
    logout();

    return handleAuthState();
  }

  function handleUpdateAuthState(authState) {
    return updateAuthState(authState);
  }

  return {
    authState,
    dispatch
  };
}

export default useAuth;
