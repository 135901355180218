import React, { useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';

import { AuthContext } from '../../contexts/AuthContext';

function HttpStatusCode(props) {
  const { redirectPathname, prevMatch } = props;
  const { authState, dispatch } = useContext(AuthContext);

  useEffect(() => {
    //Unauthorized
    if (authState.httpStatusCode === 401) {
      dispatch({type: 'logout'});
    }

    //Forbidden
    if (authState.httpStatusCode === 403) {
      dispatch({type: 'httpStatusCode', httpStatusCode: null});
    }

    //Not Found
    if (authState.httpStatusCode === 404) {
      dispatch({type: 'httpStatusCode', httpStatusCode: null});
    }
  }, [authState.httpStatusCode]);
  
  //Unauthorized
  if (authState.httpStatusCode === 401) {
    return (
      <Redirect to='/login' />
    );
  }
  
  //Forbidden
  if (authState.httpStatusCode === 403) {
    return (
      <Redirect
        to={{
          pathname: '/forbidden',
          state: {
            prevMatch: prevMatch
          }
        }}
      />
    );
  }
  
  //Not Found
  if (authState.httpStatusCode === 404) {
    return (
      <Redirect
        to={{
          pathname: redirectPathname ? redirectPathname : '/user',
          state: {
            stateAlert: {
              text: 'The request URI is incorrect, or the resource does not exist.',
              type: 'danger'
            }
          }
        }}
      />
    );
  }

  return null;
}

export default HttpStatusCode;
